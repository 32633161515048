import { lazy, Suspense } from 'react';
import { LucideProps } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';

interface LucideIconDynamicProps extends Omit<LucideProps, 'ref'> {
  name: keyof typeof dynamicIconImports;
};
export function LucideIconDynamic ({ name, ...props }: LucideIconDynamicProps) {
    const LucideIcon = lazy(dynamicIconImports[name]);
    const fallback = <div style={{ background: '#ddd', width: 24, height: 24 }}/>

    return (
        <Suspense fallback={fallback}>
            <LucideIcon {...props} />
        </Suspense>
    );
};

export function scrollTop() {
    window.scrollTo({
        top: 0, 
        behavior: "auto"
    });
};