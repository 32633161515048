import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import images
import spaceImage from "../../image/astral-wallpapers.jpg";
import floatingCow from "../../image/there-is-cow-space-suit-floating.png";
// import components
import { ImageBannerFrame } from "../display/Frame";
// import context
import { useLang } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";

export default function NoContent() {
    const { lang } = useLang();

    return (
        <ImageBannerFrame image={spaceImage}>
            <motion.img
                src={floatingCow}
                alt="Meandering object"
                className="absolute z-20"
                initial={{ x: window.innerWidth, y: window.innerHeight, scale: 1, opacity: 1 }}
                animate={{
                    x: [window.innerWidth / 3, window.innerWidth / 2, -500], 
                    y: [window.innerHeight * 0.8, window.innerHeight / 2, -500], 
                    rotate: [0, -20, 25, -50, -360, -30, 360],
                    scale: [1, 1, 0.8, 0.6, 0.3], 
                }}
                transition={{
                    duration: 12, 
                    ease: "easeInOut", 
                    repeatDelay: 1,
                    repeat: Infinity,
                }}
                onAnimationComplete={() => { console.log("Animation Complete"); }}
            />
            <div className="m-auto lg:w-full sm:w-[75%] z-30">
                <div className="mb-10">
                    <p className="text-left text-2xl text-white font-bold sm:leading-none">{ typedVocab["No Content 1"][lang] }</p>
                    <p className="text-left text-lg text-white sm:mt-2">{ typedVocab["No Content 2"][lang] }</p>
                </div>
                <div className="w-fit">
                    <Link to={ lang === "Zh" ? "/tw" : "/" } className="group flex items-center justify-center gap-2 rounded-md text-base text-teal-500 px-2 py-1 
                                            hover:bg-teal-500 hover:gap-4 transition-all duration-300">
                        <p className="text-white font-bold">{ typedVocab["Back to Home"][lang] }</p>
                        <FontAwesomeIcon className="text-white" icon={faArrowRight} />
                    </Link>
                </div>
            </div>
        </ImageBannerFrame>
    )
};


export function LoadingContent() {
    return (
        <div className="h-screen w-screen flex items-center justify-center bg-grey-900">
            <div className="h-fit flex items-center justify-center">
                <p className="text-2xl text-white font-semibold">L</p>
                <div className="relative w-8 h-8">
                    <div className="absolute w-5 h-5 rounded-full bg-grey-900 z-50 m-1.5" />
                    <motion.div className="absolute w-full h-full border-[5px] border-t-transparent border-white rounded-full z-30"
                        animate={{ rotate: 360 }}
                        transition={{
                            duration: 1,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    />
                    <div className="absolute w-full h-full rounded-full bg-grey-900/80 z-20" />
                    <div className="absolute w-full h-full rounded-full bg-white z-10" />
                </div>
                <p className="text-2xl text-white font-semibold">ADING</p>
            </div>
        </div>
    )
};