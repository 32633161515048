import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import energyLogo from "../../image/Logo_energy.svg";
import bannerImg from "../../image/energy/tree-growing-from-motherboard.jpg";
import fusionImg from "../../image/energy/other_nuclear_fusion.png";
// import data
import contentData from "../../data/content/energy/energyContent.json";
import AiToolsData from "../../data/aiTools.json";
import { DataStructure, DataKeys } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, SpacingFrame } from "../display/Frame";
import { BusinessBannerSection, BannerSection, BusinessUnitSection } from "../display/Sections";
import { SingleProductCard } from "../display/Cards";
import { ImageImport, ImageContain } from "../display/ImageFormat";
import { TextBody, TextBulletList, TitleSubtitle } from "../display/TextFormat";
// import functions
import { filterAISolutionsData } from "../../functions/DataFunctions";
import { DisplayInfo2Cols } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const typedAiTools : DataStructure = AiToolsData;
const imagePath = "image/energy/";

interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function Energy() {
    const { lang } = useLang();
    const data = typedContent["Energy"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];
    const filterSolutions = filterAISolutionsData(section1["solutions"]) as DataKeys;
    const solutionsData = Object.values(filterSolutions)[0];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ energyLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <SingleProductCard data={ solutionsData } imagePath={imagePath} />
                </SpacingFrame>
            </MainFrame>

            <BusinessUnitSection 
                title={ section2["title"][lang] } 
                text={ section2["text"][lang] } 
                bulletList={ section2["list"][lang] }
                data={ typedAiTools["agni"] }
            />

            <MainFrame>
                <SpacingFrame spacing={128}>
                    <Col2ContentFrame>
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section3["title"][lang] } left />
                            <TextBody text={ section3["text"][lang] } />
                            <TextBulletList list={ section3["list"][lang] } />
                        </SpacingFrame>
                        <ImageImport image={ section3["image"] } imagePath={imagePath} />
                    </Col2ContentFrame>

                    <Col2ContentFrame>
                        <ImageImport image={ section4["image"] } imagePath={imagePath} />
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section4["title"][lang] } left />
                            <TextBody text={ section4["text"][lang] } />
                        </SpacingFrame>
                    </Col2ContentFrame>

                </SpacingFrame>
            </MainFrame>
        </>
    )
};

export function NuclearFusion() {
    const { lang } = useLang();
    const data = typedContent["nuclear-fusion"];
    const section1 = data[1];

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />

            <MainFrame>
                <Col2ContentFrame>
                    <SpacingFrame spacing={20}>
                        <TitleSubtitle title={ section1["title"][lang] } left />
                        <TextBody text={ section1["text"][lang] } />
                        <TextBulletList list={ section1["list"][lang] } />
                        <TextBody text={ section1["text2"][lang] } />
                    </SpacingFrame>
                    <ImageContain image={fusionImg} />
                </Col2ContentFrame>
            </MainFrame>
        </>
    )
};
