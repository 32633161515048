import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import images
import bannerImg from "../../image/blog/professional-woman-working-night.jpg";
// import data
import contentData from "../../data/content/blog/blogContent.json";
import { DataKeys, DataStructure, DataFlat } from "../../types";
// import components
import { Col3ContentFrame, MainFrame, SpacingFrame } from "../display/Frame";
import { BannerSection } from "../display/Sections";
import { TextBody, TextBulletList, TitleSubtitle, TextNumberedList } from "../display/TextFormat";
import { BlogCard } from "../display/Cards";
import { UnitBadge } from "../display/Badges";
import { MoreButton, PageControl } from "../tools/Buttons";
import { NavTree } from "../navigation/NavLinkPages";
import { ShowMorePosts } from "../content/BlogNews";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { scrollTop } from "../../functions/DisplayFunctions";
import { filterDictionaryByKeys, subsetListByChunk } from "../../functions/DataFunctions";
// import context
import { useLang } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";


const typedContent : DataStructure = contentData;
const imagePath = "image/blog/";


export default function Blog() {
    const { lang } = useLang();
    const content = typedContent["content"];
    const contentKeys = Object.keys(content);

    const [selPage, setSelPage] = useState<number>(1);
    const [contentData, setContentData] = useState<DataStructure>(content);

    useEffect(() => {
        scrollTop();
        let subList = subsetListByChunk(contentKeys, selPage, 9);
        let data = filterDictionaryByKeys(subList, content);
        setContentData(data);
    }, [selPage]);


    let posts = Object.keys(contentData).map((i) => {
        let data = content[i];
        let link = "/blog/" + i;
        return (
            <BlogCard data={data} imagePath={imagePath} link={link} />
        )
    });

    return (
        <>
            <DefaultBanner />
            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ typedVocab["Latest Posts"][lang] } />
                    <Col3ContentFrame>
                        { posts }
                    </Col3ContentFrame>

                    <PageControl contentListLen={contentKeys.length} divider={9} sendSelPage={setSelPage} />
                </SpacingFrame>
            </MainFrame>
        </>
    )
};


export function BlogSubpages() {
    const { lang } = useLang();
    const { blogPosts } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [navTitle, setNavTitle] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (blogPosts) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent["content"][blogPosts] || undefined);
        };
    }, [blogPosts]);

    useEffect(() => {
        if (data) {
            setNavTitle(data["title"][lang] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };


    return (
        <>
            <DefaultBanner />
            { navTitle && <NavTree addLink={navTitle} /> }
            { data && <BlogPostsContent data={data} link={blogPosts} /> }
        </>
    )
};


//////////////////////
// LOCAL FUNCTIONS //
/////////////////////
function DefaultBanner() {
    const { lang } = useLang();
    return (
        <BannerSection image={bannerImg} title={ typedContent["title"][lang] } subtitle={ typedContent["subtitle"][lang] } />
    )
};

interface BlogPostsContentProps {
    data: DataKeys;
    link: string | undefined;
};
function BlogPostsContent({ data, link } : BlogPostsContentProps) {
    const { lang } = useLang();
    const [title, setTitle] = useState<string>("");
    const [author, setAuthor] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [imageName, setImageName] = useState<string | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure>({});
    const [buttonData, setButtonData] = useState<DataFlat>({});
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            setTitle(data["title"][lang]);
            setAuthor(data["author"][lang]);
            setDate(data["date"][lang]);
            setTags(data["tags"]);
            if (data["image"] !== "") {
                let imageSrc = require("../../" + imagePath + data["image"]);
                setImageName(imageSrc);
            } else {
                setImageName(undefined);
            };
            setContentData(data["content"]);
            setButtonData(data["button"]);
        }
    }, [data]);

    useEffect(() => {
        if (buttonData) {
            if (buttonData["label"] !== "") {
                setShowButton(true);
            } else {
                setShowButton(false);
            };
        };
    }, [buttonData]);


    let showTags = Object.keys(tags).map((i) => { 
        return (
            <UnitBadge label={ data["tags"][i] } /> 
        )
    });

    let content = Object.keys(contentData).map((i) => {
        let textData = contentData[i];
        let isBullet = textData["bullet"];
        let isNumber = textData["number"];
        let title = textData["title"][lang];
        let text = textData["text"][lang];

        if (isBullet) {
            return (
                <TextBulletList subtitle={title} list={text} />
            )
        } else if (isNumber) {
            return (
                <TextNumberedList title={title} text={text} />
            )
        } else {
            return (
                <>
                    { title && <p className="text-left font-bold -mb-5">{title}</p> }
                    <TextBody text={text} />
                </>
            )
        };
    });

    return (
        <MainFrame>
            <SpacingFrame spacing={128}>
                <SpacingFrame>
                    <div className="w-fit flex items-start justify-start gap-2">
                        { showTags }
                    </div>
                    <div className="flex flex-col">
                        <TitleSubtitle title={ title } left />
                        <p className="text-left text-grey-400">
                            <span>{ author } | </span>
                            <span>{ date }</span>
                        </p>
                    </div>
                    <SpacingFrame spacing={24}>
                        { imageName && <img className="w-full max-h-[600px] object-cover bg-center flex-shrink-0" src={imageName} /> }
                        { content }
                        { showButton && <MoreButton label={ buttonData["label"] } link={ buttonData["link"] } newTab /> }
                    </SpacingFrame>
                </SpacingFrame>

                <ShowMorePosts currPost={link} type="blog" />
            </SpacingFrame>
        </MainFrame>
    )
};