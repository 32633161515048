// import data
import peopleData from "../data/people.json";
import aiSolutionsData from "../data/aiSolutions.json";
import { DataStructure } from "../types";

const typedPeople : DataStructure = peopleData;
const typedSolutions : DataStructure = aiSolutionsData;


export function filterDictionaryByKeys(matchList: string[], data: DataStructure): DataStructure {
    const result: DataStructure = {};

    // Iterate over matchList to maintain the order of keys as in matchList
    matchList.forEach(key => {
        if (key in data) { // Check if the key exists in the data object
            // If the key is one of the desired keys, add it to the result
            if (data[key] !== null && typeof data[key] === 'object' && !Array.isArray(data[key])) {
                result[key] = data[key];
            };
        }
    });

    return result;
};


export function filterPeopleData(keys: string[]) {
    return filterDictionaryByKeys(keys, typedPeople);
};

export function filterAISolutionsData(keys: string[]) {
    return filterDictionaryByKeys(keys, typedSolutions);
};


export function calcTotalPages(length : number, dividedBy: number) {
    if (length < 0) throw new Error("Length must be a non-negative number");
    return Math.ceil(length / dividedBy);
};

export function createNumList(n: number) {
    if (n <= 0) throw new Error("Input must be a positive integer");
    return Array.from({ length: n }, (_, i) => i + 1);
};

export function subsetListByChunk(keys: string[], number: number, chunkSize: number) {
    if (number <= 0) throw new Error("Number must be a positive integer");

    const startIndex = (number - 1) * chunkSize; 
    const endIndex = startIndex + chunkSize; 

    return keys.slice(startIndex, endIndex); // Return the subset
};