import { useRef, ReactNode, CSSProperties } from "react"
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
// import context
import { useScreenSize } from "../Context";
// import global var
import { maxWidthScreen } from "../GlobalVar";

interface FrameProps {
    children: ReactNode;
};
interface FrameStyleProps extends FrameProps {
    style?: CSSProperties;
};


export function MainFrame({ children, style } : FrameStyleProps) {
    return (
        <div className="w-full h-fit overflow-x-hidden" style={style}>
            <div className="m-auto" style={{ maxWidth: `${maxWidthScreen}px` }}>
                <div className="w-full h-fit py-32 lg:px-0 sm:px-8">
                    { children }
                </div>
            </div>
        </div>
    )
};

interface ImageBannerFrameProps extends FrameProps {
    image: string;
    height?: number;
};
export function ImageBannerFrame({ children, image, height } : ImageBannerFrameProps) {
    const location = useLocation();
    return (
        <div className="w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${image})`, maxHeight: height }}>
            <div className="w-full h-full backdrop-brightness-50">
                <motion.div className="h-full m-auto content-center flex" style={{ maxWidth: `${maxWidthScreen}px` }}
                    key={location.pathname}
                    initial={{ y: "20%" }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    { children }
                </motion.div>
            </div>
        </div>
    )
};


export function HorizontalScrollFrame({ children } : FrameProps) {
    const { screenSize } = useScreenSize();

    let style = screenSize === "Large"
                ? "w-inherit h-fit grid grid-flow-col auto-cols-auto gap-8 p-8 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
                : "flex items-center justify-center flex-col gap-8 pt-16"
    return (
        <div className={style}>
            { children }
        </div>
    )
};

interface HorizontalScrollArrowsFrameProps extends FrameProps {
    arrowStyle?: CSSProperties;
};
export function HorizontalScrollArrowsFrame({ children, arrowStyle } : HorizontalScrollArrowsFrameProps) {
    const { screenSize } = useScreenSize();

    let style = screenSize === "Large"
                ? "w-inherit h-fit grid grid-flow-col auto-cols-auto gap-8 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
                : "flex items-center justify-center flex-col gap-8 py-10"

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // scroll functions
    function scrollLeft() {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust scroll distance as needed
        }
    };
    function scrollRight() {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust scroll distance as needed
        }
    };

    let scrollDisplay = <>
                            <div className="absolute left-[-6%] top-1/3 cursor-pointer" onClick={scrollLeft}>
                                <FontAwesomeIcon className="text-xl" style={arrowStyle} icon={faChevronLeft} />
                            </div>
                            <div className="absolute right-[-6%] top-1/3 cursor-pointer" onClick={scrollRight}>
                                <FontAwesomeIcon className="text-xl" style={arrowStyle} icon={faChevronRight} />
                            </div>
                        </>
    return (
        <div className="relative w-inherit">
            { screenSize === "Large" && scrollDisplay }
            <div ref={scrollContainerRef} className={ style }>
                { children }
            </div>
        </div>
    )
};


export function Col2ContentFrame({ children } : FrameProps) {
    return (
        <div className="w-full lg:grid lg:grid-cols-2 lg:grid-rows-1 sm:flex sm:flex-col gap-12 mx-auto">
            { children }
        </div>
    )
};

export function Col3ContentFrame({ children } : FrameProps) {
    return (
        <div className="w-full lg:grid lg:grid-cols-3 lg:grid-rows-1 sm:flex sm:flex-col gap-8 mx-auto">
            { children }
        </div>
    )
};

interface ColAutoFrameProps extends FrameProps {
    spacing: number;
};
ColAutoFrame.defaultProps = {
    spacing: 24
};
export function ColAutoFrame({ children, spacing } : ColAutoFrameProps) {
    return (
        <div className="h-fit w-full lg:grid lg:grid-flow-col lg:auto-cols-auto sm:flex sm:flex-col" style={{ gap: spacing }}>
            { children }
        </div>
    )
};

interface SpacingFrameProps extends FrameProps {
    spacing: number;
    center?: boolean
};
SpacingFrame.defaultProps = {
    spacing: 48
};
export function SpacingFrame({ children, spacing, center } : SpacingFrameProps) {
    return (
        <div className={`h-fit flex flex-col ${center && "items-center justify-center"}`} style={{ gap: spacing }}>
            { children }
        </div>
    )
};