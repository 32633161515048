import { DataFlat, DataKeys } from '../types';
// import data
import vocabData from "../data/vocab.json";

const screenConfigData = {
    "max-screen": 1072,
    "max-nav": 1200
} as DataFlat;

export const maxWidthScreen = screenConfigData["max-screen"];
export const maxWidthNav = screenConfigData["max-nav"];

export const typedVocab : DataKeys = vocabData;