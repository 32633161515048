import { useState, useEffect } from "react";
// import data
import blogData from "../../data/content/blog/blogContent.json";
import newsData from "../../data/content/newsroom/newsContent.json";
import { DataStructure } from '../../types';
// import components
import { MainFrame, SpacingFrame, HorizontalScrollArrowsFrame, Col3ContentFrame } from "../display/Frame";
import { TitleSubtitle } from "../display/TextFormat";
import { BlogCard, MoreNewsCard } from "../display/Cards";
import { MoreButton } from "../tools/Buttons";
// import functions
import { filterDictionaryByKeys } from "../../functions/DataFunctions";
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";

const typedBlogData : DataStructure = blogData;
const typedNewsData : DataStructure = newsData;
const blogContent = typedBlogData["content"];
const newsContent = typedNewsData["content"];

export function UpdateSummary() {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();

    return (
        <MainFrame>
            <SpacingFrame>
                <TitleSubtitle title={ typedVocab["Latest Insights Industry Trends"][lang] } />
                <div className="flex flex-col gap-4">
                    { screenSize === "Large" && <MoreButton label="View All Posts" link="/newsroom" /> }
                    <HorizontalScrollArrowsFrame arrowStyle={{ color: "#A2DADB" }}>
                        <RecentPosts currPost={undefined} type="newsroom" postNum={5} />
                    </HorizontalScrollArrowsFrame>            
                    { screenSize === "Small" && <MoreButton label="View All Posts" link="/newsroom" /> }
                </div>
            </SpacingFrame>
        </MainFrame>
    )
};

type updateType = "blog" | "newsroom";
interface RecentPostsProps {
    currPost: string | undefined;
    type: updateType;
    postNum: number;
};
export function RecentPosts({ currPost, type, postNum } : RecentPostsProps) {
    const [content, setContent] = useState<DataStructure>({});
    const [contentKeys, setContentKeys] = useState<string[]>([]);
    const [selContentKeys, setSelContentKeys] = useState<string[]>([]);
    const [recent, setRecent] = useState<DataStructure>({});

    useEffect(() => {
        if (type === "blog") {
            setContent(blogContent);
        } else if (type === "newsroom") {
            setContent(newsContent);
        };
    }, [type]);

    useEffect(() => {
        if (Object.keys(content).length !== 0) {
            setContentKeys(Object.keys(content));
            setSelContentKeys(Object.keys(content).slice(0, postNum));
        };
    }, [content]);

    useEffect(() => {
        let data = filterDictionaryByKeys(selContentKeys, content);
        setRecent(data);
    }, [selContentKeys, content]);

    useEffect(() => {
        if (currPost) {
            if (selContentKeys.includes(currPost)) {
                let contentKeys4 = contentKeys.slice(0, postNum + 1);
                let index = contentKeys4.indexOf(currPost);
                contentKeys4.splice(index, 1);
                let data = filterDictionaryByKeys(contentKeys4, content);
                setRecent(data);
            } else {
                let data = filterDictionaryByKeys(selContentKeys, content);
                setRecent(data);
            };
        };
    }, [currPost]);

    let recentPosts = Object.keys(recent).map((i) => {
        let data = recent[i];
        let link = `/${type}/${i}`;
        let imagePath = `image/${type}/`;
        if (type === "blog") {
            return (
                <BlogCard data={data} imagePath={imagePath} link={link} />
            )
        } else if (type === "newsroom") {
            return (
                <MoreNewsCard data={data} imagePath={imagePath} link={link} />
            )
        }        
    });

    return (
        <>
            { recentPosts }
        </>
    )
};

interface ShowMorePostsProps {
    currPost: string | undefined;
    type: updateType;
};
export function ShowMorePosts({ currPost, type } : ShowMorePostsProps) {
    const { lang } = useLang();
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        if (type === "blog") {
            setTitle("More Posts");
        } else if (type === "newsroom") {
            setTitle("More News");
        };
    }, [type]);

    return (
        <div className="pt-12 border-t-1">
            <SpacingFrame>
                <TitleSubtitle title={ typedVocab[title][lang] } left />
                <Col3ContentFrame>
                    <RecentPosts currPost={currPost} type={type} postNum={3} />
                </Col3ContentFrame>
            </SpacingFrame>
        </div>
    )
};