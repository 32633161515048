import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import genomicsLogo from "../../image/Logo_genom.svg";
import bannerImg from "../../image/health/woman-interacting-with-dna.jpg";
// import data
import contentData from "../../data/content/health/healthContent.json";
import { DataStructure } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, Col3ContentFrame, SpacingFrame } from "../display/Frame";
import { BannerSection, BusinessBannerSection } from "../display/Sections";
import { ImageImport } from "../display/ImageFormat";
import { TextBody, TitleSubtitle } from "../display/TextFormat";
import { AtomToolsOverview } from "../content/ToolsOverview";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const imagePath = "image/health/";

interface DefaultBannerProps {
    title: string;
    subtitle: string;
};
export function DefaultBanner({ title, subtitle } : DefaultBannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};


export default function Health() {
    const { lang } = useLang();
    const data = typedContent["Health"];
    const section1 = data[1];
    const contentData = data["content"];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ genomicsLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <AtomToolsOverview isScroll />

            <MainFrame>
                <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} />
            </MainFrame>
        </>
    )
};

export function HealthSubpages() {
    const { lang } = useLang();
    const { healthSolutions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (healthSolutions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[healthSolutions] || undefined);
        };
    }, [healthSolutions]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
        </>
    )
};