// import icons
import { Zap } from "lucide-react";
// import data types
import { DataKeys, DataStructure } from "../../types";
// import components
import { MainFrame, ImageBannerFrame, SpacingFrame } from "./Frame";
import { ToolCard } from "./Cards";
import { TitleSubtitle, TextBody, BannerText, TextBulletList } from "./TextFormat";
import { NumberCounterAnim } from "../tools/Animations";
// import context
import { useLang } from "../Context";

interface BannerSectionProps {
    image: string;
    title: string;
    subtitle: string;
    textPosition: "Top" | "Bottom";
};
BannerSection.defaultProps = {
    textPosition: "Bottom"
};
export function BannerSection({ image, title, subtitle, textPosition } : BannerSectionProps) {
    return (
        <ImageBannerFrame image={image} height={460}>
            <div className="m-auto w-full lg:px-0 sm:px-8">
                <BannerText title={ title } subtitle={ subtitle } position={ textPosition } />
            </div>
        </ImageBannerFrame>
    )
};

interface MainIdeaSectionProps {
    text: string;
};
export function MainIdeaSection({ text } : MainIdeaSectionProps) {
    return (
        <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
            <p className="text-left text-xl font-semibold">{ text }</p>
        </MainFrame>
    )
};

interface BusinessBannerSectionProps {
    bgImage: string;
    logoImage: string;
    title: string;
    subtitle: string;
};
export function BusinessBannerSection({ bgImage, logoImage, title, subtitle } : BusinessBannerSectionProps) {
    return (
        <ImageBannerFrame image={bgImage}>
            <div className="m-auto w-full lg:px-0 sm:px-8">
                <div className="flex gap-2 lg:flex-row sm:flex-col">
                    <img className="w-24" src={logoImage} alt="logo"/>
                    <div>
                        <p className="text-left lg:text-3xl sm:text-[44px] text-white font-bold sm:leading-none">{ title }</p>
                        <p className="text-left text-base text-white sm:mt-2">{ subtitle }</p>
                    </div>
                </div>
            </div>
        </ImageBannerFrame>
    )
};


interface BusinessUnitSectionProps {
    title: string;
    text: string[];
    bulletList?: string[];
    data: DataKeys;
};
export function BusinessUnitSection({ title, text, bulletList, data } : BusinessUnitSectionProps) {
    return (
        <MainFrame style={{ backgroundColor: "#A2DADB" }}>
            <div className="lg:grid lg:grid-cols-4 sm:flex sm:flex-col gap-10">
                <div className="col-span-3 flex my-auto">
                    <SpacingFrame>
                        <TitleSubtitle title={ title } left />
                        <TextBody text={ text } />
                        { bulletList && <TextBulletList list={ bulletList } /> }
                    </SpacingFrame>
                </div>
                <div className="col-span-1 mx-auto">
                    <ToolCard data={ data } />
                </div>
            </div>
        </MainFrame>
    )
};

interface StatsSectionProps {
    title: string;
    subtitle: string;
    data: DataStructure;
}
export function StatsSection({ title, subtitle, data } : StatsSectionProps) {
    const { lang } = useLang();

    let content = Object.keys(data).map((i) => {
        let contentData = data[i];
        return (
            <div className="flex flex-col gap-2">
                <p className="text-3xl text-teal-500 font-semibold">
                    <NumberCounterAnim value={ contentData["number"] } />
                    <span>{ contentData["title"][lang] }</span>
                </p>
                <p className="text-md font-medium">{ contentData["subtitle"][lang] }</p>
            </div>
        )
    });

    return (
        <SpacingFrame spacing={32} center>
            <div className="relative w-14 h-14 rounded-full bg-teal-100">
                <div className="absolute w-full h-full flex items-center justify-center">
                    <Zap className="text-teal-500" height={24} width={24} />
                </div>
            </div>
            <TitleSubtitle title={ title } subtitle={ subtitle } />
            <div className="w-full lg:grid lg:grid-flow-col lg:auto-cols-auto lg:gap-4 lg:divide-x sm:flex sm:flex-col sm:gap-10">
                { content }
            </div>
        </SpacingFrame>
    )
}

