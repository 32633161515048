import { useState, useEffect, useRef, ReactNode, CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DataFlat } from '../../types';
import { useScreenSize } from '../Context';

interface NumberCounterAnimProps {
    value: number;
};
export function NumberCounterAnim({ value } : NumberCounterAnimProps) {
    const [count, setCount] = useState(0);

    const contentRef = useRef<HTMLDivElement>(null);
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setStartAnimation(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.5 }
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        };

        return () => { observer.disconnect(); };
    }, []);

    useEffect(() => {
        if (!startAnimation) return;

        const start = performance.now();
        const step = (timestamp: number) => {
            const elapsed = timestamp - start;
            const progress = Math.min(elapsed / (1000), 1);
            const currentCount = Math.floor(progress * value);
            setCount(currentCount);
        
            if (progress < 1) {
                requestAnimationFrame(step);
            } else {
                setCount(value);
            };
        };

        requestAnimationFrame(step);
    
        return () => {
            setCount(value); // Ensure final value is set after unmounting
        };
    }, [value, startAnimation]);

    return (
        <motion.span ref={contentRef}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, amount: 0.5 }}
        >
            {count}
        </motion.span>
    );
};

export type SlideDirection = "left" | "right" | "top" | "bottom";
interface SlideInAnimProps {
    children: ReactNode;
    direction: SlideDirection;
    style?: CSSProperties;
};
export function SlideInAnim({ children, direction, style } : SlideInAnimProps) {
    const location = useLocation();
    const { screenSize } = useScreenSize();
    const initialState = {
        x: direction === "left" ? "-50%" : direction === "right" ? "50%" : "0",
        y: direction === "top" ? "-30%" : direction === "bottom" ? "30%" : "0",
        opacity: 0
    };
    const [anim, setAnim] = useState<DataFlat>(initialState);

    useEffect(() => {
        if (direction === "left") {
            setAnim({ x: "-50%", y: 0 });
        } else if (direction === "right") {
            setAnim({ x: "50%", y: 0 });
        } else if (direction === "top") {
            setAnim({ x: 0, y: "-30%" }); 
        } else if (direction === "bottom") {
            setAnim({ x: 0, y: "30%" }); 
        } else {
            setAnim({ x: 0, y: 0 });
        };
    }, [location, direction]);

    return (
        <motion.div
            style={ style }
            key={location.pathname}
            initial={ anim }
            whileInView={{ x: 0, y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true, amount: screenSize === "Large" ? 0.4 : 0.1 }}
        >
            { children }
        </motion.div>
    )
};