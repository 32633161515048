import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import financeLogo from "../../image/Logo_finance.svg";
import bannerImg from "../../image/3d-graph-computer-illustration.jpg";
import sectionImg from "../../image/finance/futuristic-robot-interacting-with-money.jpg";
// import data
import contentData from "../../data/content/finance/financeContent.json";
import AiToolsData from "../../data/aiTools.json";
import { DataStructure } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col3ContentFrame, Col2ContentFrame, SpacingFrame } from "../display/Frame";
import { BusinessBannerSection, BannerSection, BusinessUnitSection } from "../display/Sections";
import { ImageImport, ImageBanner } from "../display/ImageFormat";
import { TextBody, TextBulletList, TitleSubtitle } from "../display/TextFormat";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const typedAiTools : DataStructure = AiToolsData;
const imagePath = "image/finance/";


interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function Finance() {
    const { lang } = useLang();
    const data = typedContent["Finance"];
    const section1 = data[1];
    const section2 = data[2];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ financeLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <BusinessUnitSection 
                title={ section2["title"][lang] } 
                text={ section2["text"][lang] } 
                data={ typedAiTools["alpha"] }
            />

            <ImageBanner image={sectionImg} />
        </>
    )
};

export function FinanceSubpages() {
    const { lang } = useLang();
    const { financeSolutions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (financeSolutions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[financeSolutions] || undefined);
        };
    }, [financeSolutions]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };


    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath="image/finance/" /> }
        </>
    )
};

export function FinanceTraderAnita() {
    const { lang } = useLang();
    const data = typedContent["alpha-ai-trader-anita"];
    const section1 = data[1];

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />

            <MainFrame>
                <Col2ContentFrame>
                    <SpacingFrame spacing={20}>
                        <TitleSubtitle title={ section1["title"][lang] } left />
                        <TextBody text={ section1["text"][lang] } />
                        <TextBulletList list={ section1["list"][lang] } />
                    </SpacingFrame>
                    <ImageImport image={ section1["image"] } imagePath={imagePath} />
                </Col2ContentFrame>
            </MainFrame>
        </>
    )
};