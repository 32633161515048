// import data
import atomToolsData from "../../data/content/about/atom/atomTools.json";
import ardiToolsData from "../../data/content/about/ardi/ardiTools.json";
import { DataStructure } from '../../types';
// import components
import { MainFrame, SpacingFrame, HorizontalScrollArrowsFrame } from "../display/Frame";
import { ToolCard } from "../display/Cards";
import { TitleSubtitle } from "../display/TextFormat";
import { MoreButton } from "../tools/Buttons";
// import context
import { useLang } from "../Context";

const typedAtomTools : DataStructure = atomToolsData;
const typedArdiTools : DataStructure = ardiToolsData;

interface ToolsOverviewProps {
    isScroll?: boolean;
};
export function AtomToolsOverview({ isScroll } : ToolsOverviewProps) {
    const { lang } = useLang();

    // tools
    let toolCards = Object.keys(typedAtomTools["content"]).map((i) => {
        let data = typedAtomTools["content"][i];
        return (
            <ToolCard data={data} isBulletList width={240} height={244} />
        )
    });

    let layout = isScroll
                ?   <>
                        <HorizontalScrollArrowsFrame>
                            { toolCards }
                        </HorizontalScrollArrowsFrame>
                        <MoreButton label="View All Tools" link="/about/technologies/atom" darkMode />
                    </>
                :   <div className="lg:grid lg:grid-cols-4 sm:flex sm:flex-col gap-5 mx-auto">
                        { toolCards }
                    </div>

    return (
        <MainFrame style={{ backgroundColor: "#A2DADB" }}>
            <SpacingFrame spacing={40}>
                <TitleSubtitle title={ typedAtomTools["title"][lang] } />
                <p>{ typedAtomTools["subtitle"][lang] }</p>
                { layout }
            </SpacingFrame>
        </MainFrame>
    )
};

export function ArdiToolsOverview({ isScroll } : ToolsOverviewProps) {
    const { lang } = useLang();

    // tools
    let toolCards = Object.keys(typedArdiTools["content"]).map((i) => {
        let data = typedArdiTools["content"][i];
        return (
            <ToolCard data={data} isBulletList width={240} height={244} />
        )
    });

    let layout = isScroll
                ?   <>
                        <HorizontalScrollArrowsFrame>
                            { toolCards }
                        </HorizontalScrollArrowsFrame>
                        <MoreButton label="View All Tools" link="/about/technologies/ardi" darkMode />
                    </>
                :   <div className="lg:grid lg:grid-cols-4 sm:flex sm:flex-col gap-5 mx-auto">
                        { toolCards }
                    </div>

    return (
        <MainFrame style={{ backgroundColor: "#A2DADB" }}>
            <SpacingFrame spacing={40}>
                <TitleSubtitle title={ typedArdiTools["title"][lang] } />
                <p>{ typedArdiTools["subtitle"][lang] }</p>
                { layout }
            </SpacingFrame>
        </MainFrame>
    )
};