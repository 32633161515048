import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import data
import contentData from "../../../data/content/about/atom/atomContent.json";
import { DataStructure } from '../../../types';
// import components
import { NavTree, ExploreOtherServices } from "../../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, SpacingFrame } from "../../display/Frame";
import { TextBody, TitleSubtitle } from "../../display/TextFormat";
import { ImageImport } from "../../display/ImageFormat";
import { TechBanner } from "./About";
import { AtomToolsOverview } from "../../content/ToolsOverview";
import NoContent, { LoadingContent } from "../../content/NoContent";
import { SlideInAnim } from "../../tools/Animations";
// import functions
import { DisplayInfo2Cols } from "../../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../../Context";

const typedContent : DataStructure = contentData;
const imagePath = "image/about/Atom/";


export default function Atom() {
    const { lang } = useLang();

    const data = typedContent["atom"];
    const section1 = data[1];
    const section2 = data[2];

    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={128}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <div className="flex flex-col gap-6">
                            <TextBody text={ section1["content"]["text"][lang] } />
                        </div>
                        <SlideInAnim direction="right">
                            <div className="flex flex-col gap-6">
                                <ImageImport image="virus_graph.gif" imagePath={imagePath} />
                                <ImageImport image="atom1.jpg" imagePath={imagePath} />
                            </div>
                        </SlideInAnim>
                    </Col2ContentFrame>
                    <SlideInAnim direction="bottom">
                        <ImageImport image="Atom_foundation.png" imagePath={imagePath} />
                    </SlideInAnim>
                </SpacingFrame>
            </MainFrame>

            <AtomToolsOverview />

            <MainFrame>
                <SpacingFrame>
                    <SlideInAnim direction="bottom">
                        <ImageImport image="atomoverview.png" imagePath={imagePath} />
                    </SlideInAnim>
                    <Col2ContentFrame>
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section2["title"][lang] } left />
                            <TextBody text={ section2["text"][lang] } />
                        </SpacingFrame>
                        <SlideInAnim direction="right">
                            <ImageImport image="atom2.png" imagePath={imagePath} />
                        </SlideInAnim>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <ExploreOtherServices titleKey="Explore Medical Solutions" servicesList={["drugomics", "health"]} />
        </>
    )
};


export function AtomTools() {
    const { lang } = useLang();
    const { atomTools } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (atomTools) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[atomTools] || undefined);
        };
    }, [atomTools]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };


    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
            <ExploreOtherServices titleKey="Explore Medical Solutions" servicesList={["drugomics", "health"]} />
        </>
    )
};