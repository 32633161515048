import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import securityLogo from "../../image/Logo_security.svg";
import bannerImg from "../../image/security/cyber-security-concept-digital-art.jpg";
import lockImg from "../../image/security/ai_cyber_2.jpg";
import lock2Img from "../../image/security/apt4.jpg";
// import data
import contentData from "../../data/content/security/securityContent.json";
import AiToolsData from "../../data/aiTools.json";
import { DataStructure } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, SpacingFrame } from "../display/Frame";
import { BannerSection, BusinessBannerSection, BusinessUnitSection } from "../display/Sections";
import { ImageContain, ImageImport } from "../display/ImageFormat";
import { TextBody, TextBulletList, TextParagraphHeader, TitleSubtitle } from "../display/TextFormat";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const typedAiTools : DataStructure = AiToolsData;
const imagePath = "image/security/";


interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function Security() {
    const { lang } = useLang();
    const data = typedContent["Security"];
    const section1 = data[1];
    const section2 = data[2];
    const contentData = data["content"];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ securityLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <BusinessUnitSection 
                title={ section2["title"][lang] } 
                text={ section2["text"][lang] } 
                data={ typedAiTools["alice"] }
            />

            <MainFrame>
                <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} />
            </MainFrame>
        </>
    )
};

export function SecuritySubpages() {
    const { lang } = useLang();
    const { securitySolutions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (securitySolutions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[securitySolutions] || undefined);
        };
    }, [securitySolutions]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
        </>
    )
};