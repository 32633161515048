import { useState, useEffect } from "react";
import dynamicIconImports from 'lucide-react/dynamicIconImports';
// import data type
import { DataStructure } from "../../types";
// import icons
import { ArrowUpRight } from "lucide-react";
// import components
import { SpacingFrame } from "./Frame";
// import functions
import { LucideIconDynamic } from "../../functions/DisplayFunctions";
// import context
import { useLang } from "../Context";

interface TitleProps {
    title: string;
    subtitle?: string;
};

type position = "Top" | "Bottom"
interface BannerTextProps extends TitleProps {
    position: position;
};
export function BannerText({ title, subtitle, position } : BannerTextProps) {
    let text = <p className="text-left text-lg text-white">{ subtitle }</p>
    return (
        <div className="lg:p-0">
            { position === "Top" && text }
            <p className="text-left text-2xl text-white font-bold sm:leading-none">{ title }</p>
            { position === "Bottom" && text }
        </div>
    )
};

interface TitleSubtitleProps extends TitleProps {
    left?: boolean;
    spacing?: number;
};
export function TitleSubtitle({ title, subtitle, left, spacing } : TitleSubtitleProps) {
    return (
        <SpacingFrame spacing={spacing ? spacing : 0}>
            <p className={`text-xl font-semibold ${left && "text-left"}`}>{ title }</p>
            <p className={`text-base text-grey-400 ${left && "text-left"}`}>{ subtitle }</p>
        </SpacingFrame>
    )
};

interface CapsTextProps {
    text: string;
    style?: string;
};
export function CapsText({ text, style } : CapsTextProps) {
    return (
        <p className={`text-base uppercase ${style}`}>{ text }</p>
    )
};


interface TextArrowProps {
    label: string;
};
export function TextArrow({ label } : TextArrowProps) {
    return (
        <div className="w-full h-min flex text-md">
            <p className="w-full text-left text-wrap font-bold group-hover:text-teal-500">{ label }</p>
            <div className="relative w-8 group-hover:text-teal-500">
                <ArrowUpRight className="absolute top-0 right-0" size={25} />
            </div>
        </div>
    )
};

interface TextBulletListProps {
    subtitle?: string;
    list: string[];
    subtitleStyle?: string;
    textStyle?: string;
};
export function TextBulletList({ subtitle, list, subtitleStyle, textStyle } : TextBulletListProps) {
    let showList = list.map((i) => {
        return (
            <li className={`list-disc text-left leading-relaxed ${textStyle}`}>{i}</li>
        )
    });
    
    return (
        <div>
            <p className={`text-left ${subtitleStyle}`}>{ subtitle }</p>
            <ul className="list-outside pl-6 text-wrap">
                { showList }
            </ul>
        </div>
    )
};

interface TextBodyProps {
    title?: string;
    text: string[];
};
export function TextBody({ title, text } : TextBodyProps) {
    let textContent = text.map((i) => {
        return (
            <p className="text-left leading-relaxed">{ i }</p>
        )
    });

    return (
        <>
            { title && <p className="text-lg text-left font-semibold">{ title }</p> }
            { textContent }
        </>
    )
};

export function TextNumberedList({ title, text } : TextBodyProps) {
    let textContent = text.map((i) => {
        return (
            <li className="list-decimal text-left leading-relaxed">{i}</li>
        )
    });

    return (
        <>
            { title && <p className="text-lg text-left font-semibold">{ title }</p> }
            <ul className="list-outside pl-6 text-wrap">
                { textContent }
            </ul>
        </>
    )
};

interface TextDataProps {
    data: DataStructure;
    isBulletList?: boolean;
};
export function TextParagraphHeader({ data, isBulletList } : TextDataProps) {
    const { lang } = useLang();
    let content = Object.keys(data).map((i) => {
        let paragraphData = data[i];
        return (
            <div>
                <p className="text-left font-bold">{ paragraphData["title"][lang] }</p>
                { isBulletList 
                    ? <TextBulletList list={ paragraphData["text"][lang] } />
                    : <TextBody text={ paragraphData["text"][lang] } />
                }
            </div>
        )
    });

    return (
        <>
            { content }
        </>
    )
};


interface ShowIconTextProps {
    text: string;
    iconName: string;
}
export function ShowIconText({ text, iconName } : ShowIconTextProps) {
    const [icon, setIcon] = useState<keyof typeof dynamicIconImports>("layers");

    useEffect(() => {
        setIcon(iconName as keyof typeof dynamicIconImports);
    }, [iconName])

    return (
        <div className="flex gap-2">
            <LucideIconDynamic className="text-grey-400" name={ icon } size={20} />
            <p className="text-left text-sm text-grey-400">{ text }</p>
        </div>
    )
};