import { useState, useEffect } from "react";
// import data
import badgeUnitData from "../../data/badgesUnits.json";
import { DataStructure } from '../../types';
// import context
import { useLang } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";

const typedBadgeUnit : DataStructure = badgeUnitData;

interface UnitBadgeProps {
    label: string;
};
export function UnitBadge({ label } : UnitBadgeProps) {
    const { lang } = useLang();
    const [text, setText] = useState<string>("");
    const [bgColor, setBgColor] = useState<string>("");
    const [textColor, setTextColor] = useState<string>("");

    useEffect(() => {
        if (label) {
            try {
                setText(typedVocab[label][lang]);
                setBgColor(typedBadgeUnit[label]["bg"]);
                setTextColor(typedBadgeUnit[label]["text"]);
            } catch {
                setText(label);
                setBgColor("#F8F9FA");
                setTextColor("#333333");
            };
        }
    }, [label, lang])
    
    return (
        <div className="text-sm rounded-full px-2" style={{ backgroundColor: bgColor }}>
            <p className="font-medium" style={{ color: textColor }}>{ text }</p>
        </div>
    )
};