import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import manufactureLogo from "../../image/Logo_manufacture.svg";
import bannerImg from "../../image/manufacturing/engineer-controlling-robotic-arm.jpg";
// import data
import contentData from "../../data/content/manufacturing/manufacturingContent.json";
import AiToolsData from "../../data/aiTools.json";
import { DataStructure } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { Col2ContentFrame, MainFrame, SpacingFrame } from "../display/Frame";
import { BannerSection, BusinessBannerSection, BusinessUnitSection } from "../display/Sections";
import { TitleSubtitle } from "../display/TextFormat";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const typedAiTools : DataStructure = AiToolsData;
const imagePath = "image/manufacturing/";

interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function Manufacturing() {
    const { lang } = useLang();
    const data = typedContent["Manufacturing"];
    const section1 = data[1];
    const content = data[2];
    const section3 = data[3];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ manufactureLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <BusinessUnitSection 
                title={ section3["title"][lang] } 
                text={ section3["text"][lang] } 
                bulletList={ section3["list"][lang] }
                data={ typedAiTools["agni"] }
            />

            <DisplayInfo2Cols contentData={content} imagePath={imagePath} /> 
        </>
    )
};


export function ManufacturingSubpages() {
    const { lang } = useLang();
    const { manufacturingSolutions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (manufacturingSolutions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[manufacturingSolutions] || undefined);
        };
    }, [manufacturingSolutions]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
        </>
    )
};
