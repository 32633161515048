import { useState, useEffect, useContext, createContext, ReactNode, Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// create parent provider
export default function AppProviders({ children } : { children: ReactNode }) {
    return (
        <MobileNavOpenProvider>
            <NavTabProvider>
                <LangProvider>
                    <ScreenSizeProvider>
                        {children}
                    </ScreenSizeProvider>
                </LangProvider>
            </NavTabProvider>
        </MobileNavOpenProvider>
    );
};


///////////////////
// SCREEN WIDTH //
//////////////////
type ScreenSize = "Small" | "Large";

interface ScreenSizeContextProps {
    screenSize: ScreenSize;
    setScreenSize: Dispatch<SetStateAction<ScreenSize>>;
};
const ScreenSizeContext = createContext<ScreenSizeContextProps | null>(null);

interface ScreenSizeProviderProps {
    children: ReactNode;
}
export function ScreenSizeProvider({ children } : ScreenSizeProviderProps) {
  const [screenSize, setScreenSize] = useState<ScreenSize>("Large");

    useEffect(() => {
        handleResize();
        function handleResize() {
            const width = window.innerWidth;
            if (width <= 1024) {
                setScreenSize("Small")
            } else if (width > 1024) {
                setScreenSize("Large")
            };
        };

        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ScreenSizeContext.Provider value={{ screenSize, setScreenSize }}>
            {children}
        </ScreenSizeContext.Provider>
    );
};

export function useScreenSize() {
    const context = useContext(ScreenSizeContext);
    if (!context) {
        throw new Error('useScreenSize must be used within a ScreenSizeProvider');
    };
    return context
};


///////////////
// LANGUAGE //
//////////////
interface LangContextProps {
    lang: string;
    setLang: Dispatch<SetStateAction<string>>;
};
export const LangContext = createContext<LangContextProps | null>(null);

interface LangProviderProps {
    children: ReactNode;
};
export function LangProvider({ children } : LangProviderProps) {
    const [lang, setLang] = useState<string>("En");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.startsWith("/tw")) {
            setLang("Zh");
        } else {
            setLang(lang);
        };
    }, [location.pathname]);

    useEffect(() => {
        const path = location.pathname;
        if (lang === "Zh" && !path.startsWith("/tw")) {
            navigate("tw" + path);
        } else if (path.startsWith("/tw")) {
            navigate(path.slice(3));
        };
    }, [lang]);
    
    return (
        <LangContext.Provider value={{ lang, setLang }}>
            { children }
        </LangContext.Provider>
    );
};

export function useLang() {
    const info = useContext(LangContext);
    if (!info) {
        throw new Error('useLang must be used within a StateContext');
    };
    return info
};

/////////////
// NAV TAB //
/////////////
interface NavTabContextProps {
    navTab: {"Name": string, "Open": boolean, "LeftPos": number};
    setNavTab: Dispatch<SetStateAction<{"Name": string, "Open": boolean, "LeftPos": number}>>;
};
export const NavTabContext = createContext<NavTabContextProps | null>(null);


interface NavTabProviderProps {
    children: ReactNode;
};
export function NavTabProvider({ children } : NavTabProviderProps) {
    const [navTab, setNavTab] = useState<{"Name": string, "Open": boolean, "LeftPos": number}>({"Name": "", "Open": false, "LeftPos": 0});
    
    return (
        <NavTabContext.Provider value={{ navTab, setNavTab }}>
            { children }
        </NavTabContext.Provider>
    );
};

export function useNavTab() {
    const info = useContext(NavTabContext);
    if (!info) {
        throw new Error('useNavTab must be used within a StateContext');
    }
    return info
};

/////////////////
// MOBILE NAV //
////////////////
interface MobileNavOpenContextProps {
    mobileNavOpen: boolean;
    setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
};
export const MobileNavOpenContext = createContext<MobileNavOpenContextProps | null>(null);

interface MobileNavOpenProviderProps {
    children: ReactNode;
};
export function MobileNavOpenProvider({ children } : MobileNavOpenProviderProps) {
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    
    return (
        <MobileNavOpenContext.Provider value={{ mobileNavOpen, setMobileNavOpen }}>
            { children }
        </MobileNavOpenContext.Provider>
    );
};

export function useMobileNavOpen() {
    const info = useContext(MobileNavOpenContext);
    if (!info) {
        throw new Error('useMobileNavOpen must be used within a MobileNavOpenContext');
    }
    return info
};