import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Send, ArrowLeft, ArrowRight } from "lucide-react";
// import functions
import { calcTotalPages, createNumList } from "../../functions/DataFunctions";
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";


interface MoreButtonProps {
    label?: string;
    link: string;
    darkMode?: boolean;
    newTab?: boolean;
};
MoreButton.defaultProps = {
    label: "Learn More"
};
export function MoreButton({ label, link, darkMode, newTab } : MoreButtonProps) {
    const [text, setText] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const { lang } = useLang();
    const excludeList = ["mailto", "http", ".com", "://", "www."];

    useEffect(() => {
        if (label) {
            try {
                setText(typedVocab[label][lang]);
            } catch {
                setText("");
            }
        } else {
            setText("");
        }
    }, [lang, label]);

    useEffect(() => {
        if (excludeList.some(key => link.includes(key))) {
            setUrl(link);
        } else {
            if (lang === "Zh") {
                if (link.startsWith("/")) {
                    setUrl("/tw" + link);
                } else {
                    setUrl(link);
                };
            } else {
                setUrl(link);
            };
        };
    }, [link, lang])

    return (
        <div className="w-fit">
            <Link to={url} target={ newTab ? "_blank" : "" }
                className={`group flex items-center justify-center gap-2 rounded-md text-base text-teal-500 px-3 py-1 
                            ${darkMode ? "hover:bg-teal-500" : "hover:bg-teal-100/50"} hover:gap-4 transition-all duration-300`}>
                <p className={`text-teal-500 font-bold ${darkMode && "group-hover:text-white"}`}>{ text }</p>
                <FontAwesomeIcon className={`${darkMode && "group-hover:text-white"}`} icon={faArrowRight} />
            </Link>
        </div>
    )
};

interface SubmitButtonProps {
    disabled?: boolean;
}
export function SubmitButton({ disabled } : SubmitButtonProps) {
    const { lang } = useLang();
    let style = disabled
                ? "group w-full flex items-center justify-center gap-2 rounded-md px-20 py-1 border-2 border-grey-200 text-base text-grey-200 bg-grey-100 cursor-not-allowed"
                : "group w-full flex items-center justify-center gap-2 rounded-md px-20 py-1 border-2 border-grey-500 text-base text-white bg-grey-500 hover:text-teal-300"
    
    return (
        <button className={ style }>
            <p className="text-white group-hover:text-teal-300">{ typedVocab["Submit"][lang] }</p>
            <Send size={16} />
        </button>
    )
};

export function ContactUsButton() {  
    const { lang } = useLang();  
    return (
        <button className="group w-fit rounded-md px-20 py-1 border-2 border-grey-500 text-white bg-grey-500 hover:text-teal-300">
            <a className="flex items-center justify-center gap-2" href="mailto:info@graphen.ai">
                <p className="text-white group-hover:text-teal-300">{ typedVocab["Contact Us"][lang] }</p>
                <Send size={16} />
            </a>
        </button>
    )
};

interface PageControlProps {
    contentListLen: number;
    divider: number;
    sendSelPage: (page: number) => void;
}
export function PageControl({ contentListLen, divider, sendSelPage } : PageControlProps) {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();
    const [pageList, setPageList] = useState<number[]>([1]);
    const [selPage, setSelPage] = useState<number>(1);
    const [disablePrev, setDisablePrev] = useState<boolean>(false);
    const [disableNext, setDisableNext] = useState<boolean>(false);

    useEffect(() => {
        let pageNum = calcTotalPages(contentListLen, divider)
        setPageList(createNumList(pageNum));
    }, []);

    useEffect(() => {
        sendSelPage(selPage);
    }, [selPage])

    useEffect(() => {
        if (selPage === 1) {
            setDisablePrev(true);
        } else {
            setDisablePrev(false);
        };
        if (selPage === pageList.at(-1)) {
            setDisableNext(true);
        } else {
            setDisableNext(false);
        };
    }, [pageList, selPage]);

    function navPage(next: boolean) {
        if (next) {
            setSelPage(selPage + 1);
        } else {
            setSelPage(selPage - 1);
        };
    };

    let showPageIndex = pageList.map((i) => {
        return (
            <div className={`group w-8 h-8 flex items-center justify-center rounded-lg cursor-pointer 
                            hover:bg-grey-100/30 transition-all duration-200 ${i === selPage && "bg-grey-800"}`}
                onClick={() => setSelPage(i)}
            >
                <p className={`${ i === selPage && "text-white" } group-hover:text-grey-800`}>{ i }</p>
            </div>
        )
    });

    return (
        <div className="h-full w-fit flex gap-4 m-auto">
            <div className={`w-fit h-8 flex gap-2 items-center justify-center rounded-lg px-2 
                            ${ !disablePrev && "hover:bg-grey-100/30 cursor-pointer" }`} onClick={() => !disablePrev && navPage(false)}>
                <ArrowLeft className={`${ disablePrev && "text-grey-400/50" }`} size={20} />
                { screenSize === "Large" && <p className={`${ disablePrev && "text-grey-400/50" }`}>{ typedVocab["Previous"][lang] }</p> }
            </div>
            <div className="flex gap-2">
                { showPageIndex }
            </div>
            <div className={`w-fit h-8 flex gap-2 items-center justify-center rounded-lg px-2 
                            ${ !disableNext && "hover:bg-grey-100/30 cursor-pointer" }`} onClick={() => !disableNext && navPage(true)}>
                { screenSize === "Large" && <p className={`${ disableNext && "text-grey-400/50" }`}>{ typedVocab["Next"][lang] }</p> }
                <ArrowRight className={`${ disableNext && "text-grey-400/50" }`} size={20} />
            </div>
        </div>
    )
};