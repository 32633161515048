import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import data
import contentData from "../../../data/content/about/AIPlatformContent.json";
import { DataKeys, DataStructure } from "../../../types";
// import components
import { NavTree, ExploreOtherServices } from "../../navigation/NavLinkPages";
import { TechBanner } from "./About";
import NoContent, { LoadingContent } from "../../content/NoContent";
// import function
import { DisplayInfo2Cols } from "../../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../../Context";

const typedContent : DataStructure = contentData;
const imagePath = "image/about/aiPlatforms/";


export default function AboutTechSubpages() {
    const { lang } = useLang();
    const { aboutTech } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);
    const [exploreData, setExploreData] = useState<DataKeys | undefined>(undefined);

    useEffect(() => {
        if (aboutTech) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[aboutTech] || undefined);
        };
    }, [aboutTech]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setExploreData(data["explore"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };

    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} title={ data["header"][lang] } /> }
            { exploreData && <ExploreOtherServices titleKey={ exploreData["title"] } servicesList={ exploreData["list"] } /> }
        </>
    )
};
