import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import data
import contentData from "../../../data/content/about/ardi/ardiContent.json";
import { DataStructure } from '../../../types';
// import components
import { NavTree, ExploreOtherServices } from "../../navigation/NavLinkPages";
import { MainFrame, SpacingFrame, Col2ContentFrame } from "../../display/Frame";
import { TitleSubtitle, TextBody } from "../../display/TextFormat";
import { ImageImport } from "../../display/ImageFormat";
import { TechBanner } from "./About";
import { ArdiToolsOverview } from "../../content/ToolsOverview";
import NoContent, { LoadingContent } from "../../content/NoContent";
import { SlideInAnim } from "../../tools/Animations";
// import functions
import { DisplayInfo2Cols } from "../../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../../Context";

const typedContent : DataStructure = contentData;
const imagePath = "image/about/Ardi/";


export default function Ardi() {
    const { lang } = useLang();

    const data = typedContent["ardi"];
    const section1 = data[1];
    const contentData = data["content"];


    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={32}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <div className="flex flex-col gap-6">
                            <TextBody text={ section1["content"]["text"][lang] } />
                        </div>
                        <SlideInAnim direction="right" style={{ margin: "auto" }}>
                            <ImageImport image="brain-functions.png" imagePath={imagePath} />
                        </SlideInAnim>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <ArdiToolsOverview />

            <MainFrame>
                <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} />
            </MainFrame>

            <ExploreOtherServices titleKey="Explore Core Solutions" servicesList={["core", "robotics"]} />
        </>
    )
};

export function ArdiTools() {
    const { lang } = useLang();
    const { ardiTools } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (ardiTools) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[ardiTools] || undefined);
        };
    }, [ardiTools]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };


    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
        </>
    )
}