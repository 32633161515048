import { useRef } from "react";
import emailjs from '@emailjs/browser';
// import icons
import { Rocket } from "lucide-react";
// import components
import { MainFrame, SpacingFrame } from "../display/Frame";
import { ContactUsButton, SubmitButton } from "../tools/Buttons";
// import context
import { useLang } from "../Context";
// import global var
import { maxWidthScreen, typedVocab } from "../GlobalVar";

export default function JoinUsSection() {
    const { lang } = useLang();
    return (
        <MainFrame style={{ backgroundColor: "#3299A0" }}>
            <SpacingFrame spacing={32}>
                <div className="flex gap-2">
                    <Rocket className="text-white my-auto" width={20} height={20} />
                    <p className="text-left text-lg text-white">{ typedVocab["Join Us 1"][lang] }</p>
                </div>
                <div>
                    <p className="text-left text-xl text-white font-semibold">{ typedVocab["Join Us 2"][lang] }</p>
                    <p className="text-left text-white font-light">{ typedVocab["Join Us 3"][lang] }</p>
                </div>
                <ContactUsButton />
            </SpacingFrame>
        </MainFrame>
    )

};

export function JoinUsContactForm() {
    const { lang } = useLang();
    return (
        <div className="w-full h-fit overflow-x-hidden bg-teal-400">
            <div className="m-auto" style={{ maxWidth: `${maxWidthScreen}px` }}>
                <div className="w-full h-fit py-16 lg:px-0 sm:px-8">
                    <div className="lg:grid lg:grid-cols-3 sm:flex sm:flex-col lg:gap-4 sm:gap-12">
                        <div className="col-span-2 my-auto flex flex-col lg:gap-8 sm:gap-12">
                            <div className="flex gap-2">
                                <Rocket className="text-white my-auto" width={20} height={20} />
                                <p className="text-left text-lg text-white">{ typedVocab["Join Us 1"][lang] }</p>
                            </div>
                            <div>
                                <p className="text-left text-xl text-white font-semibold">{ typedVocab["Join Us 2"][lang] }</p>
                                <p className="text-left text-white font-light">{ typedVocab["Join Us 3"][lang] }</p>
                            </div>
                        </div>
                        <div className="col-span-1 w-full m-auto">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

//////////////////
// NOT IN USE: //
/////////////////
// unable to set up service with info@graphen.ai 
export function ContactForm() {
    const form = useRef<HTMLFormElement>(null);
  
    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        if (form.current) {
            const currForm = form.current;
            emailjs.sendForm(
                "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
                "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
                form.current,
                "YOUR_PUBLIC_KEY"  // Replace with your EmailJS public key
            ).then(
                (result) => {
                console.log("Email sent successfully:", result.text);
                alert("Your message has been sent!");
                currForm.reset(); // Reset the form after submission
                },
                (error) => {
                console.error("Email sending error:", error.text);
                alert("Failed to send message. Please try again later.");
                }
            );
        };
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="w-full h-fit bg-white flex flex-col gap-6 rounded p-6">
            <div className="flex flex-col gap-2">
                <p className="text-left text-sm">Full Name</p>
                <input className="text-left text-sm border rounded-md py-1.5 px-3 focus:outline-teal-300"
                    type="text"
                    name="user_name"
                    placeholder="Type your name"
                    required
                />
            </div>
            <div className="flex flex-col gap-2">
                <p className="text-left text-sm">Email</p>
                <input className="text-left text-sm border rounded-md py-1.5 px-3 focus:outline-teal-300"
                    type="email"
                    name="user_email"
                    placeholder="Type your email"
                    required
                />
            </div>
            <div className="flex flex-col gap-2">
                <p className="text-left text-sm">Category</p>
            </div>
            <div className="flex flex-col gap-2">
                <p className="text-left text-sm">Message</p>
                <textarea
                    name="message"
                    placeholder="Type your message"
                    className="text-left text-sm border rounded-md py-1.5 px-3 focus:outline-teal-300"
                    rows={5}
                    required
                ></textarea>
            </div>
            <SubmitButton />
        </form>
    )
    return (
      <div className="w-full max-w-md mx-auto p-4 border rounded-md shadow-lg">
        <h1 className="text-2xl font-semibold mb-4">Contact Us</h1>
        <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-4">
          <input
            type="text"
            name="user_name"
            placeholder="Your Name"
            className="p-2 border rounded-md"
            required
          />
          <input
            type="email"
            name="user_email"
            placeholder="Your Email"
            className="p-2 border rounded-md"
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            className="p-2 border rounded-md"
            rows={5}
            required
          ></textarea>
          <ContactUsButton />
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Send Message
          </button>
        </form>
      </div>
    );
  };