import { CSSProperties } from "react";

interface ImageProps {
    image: string;
};
export function ImageBanner({ image } : ImageProps) {
    return (
        <div className="w-full h-screen max-h-96 bg-cover bg-center" style={{ backgroundImage: `url(${image})`}} />
    )
};

export function ImageContain({ image } : ImageProps) {
    return (
        <div className="flex w-full h-full items-stretch">
            <img className="w-full h-full object-cover bg-center flex-shrink-0" src={image} />
        </div>
    )
};

interface ImageZoomProps extends ImageProps {
    style?: CSSProperties;
};
export function ImageZoom({ image, style } : ImageZoomProps) {
    return (
        <div className="relative w-full lg:min-w-[336px] h-52 mb-2 overflow-hidden" style={style}>
            <img className="absolute h-full w-full object-cover object-center select-none transition-transform duration-500 ease-in-out group-hover:scale-125" src={image} alt="" />
        </div>
    )
};

interface ImageImportProps extends ImageProps {
    imagePath?: string;
};
export function ImageImport({ image, imagePath } : ImageImportProps) {
    const imageSrc = require("../../" + imagePath + image);
    return (
        <div className="m-auto">
            <img src={imageSrc} />
        </div>
    )
};

interface ImageImportContainProps extends ImageImportProps {
    maxHeight: number;
};
ImageImportContain.defaultProps = {
    maxHeight: 580
};
export function ImageImportContain({ image, imagePath, maxHeight } : ImageImportContainProps) {
    const imageSrc = require("../../" + imagePath + image);
    return (
        <div className="flex w-full h-full items-stretch" style={{ maxHeight: `${maxHeight}px` }}>
            <img className="w-full h-full lg:max-h-full sm:max-h-[200px] object-cover bg-center flex-shrink-0" src={imageSrc} />
        </div>
    )
};
