import { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { CirclePlus, ArrowUpRight } from "lucide-react";
// import images
import drugBaseImg from "../../image/drugomics/drug-design-base.png";
import drugPillImg from "../../image/drugomics/drug-design-pill.png";
import vaccineBaseImg from "../../image/drugomics/vaccine-design-base.png";
import vaccineSyringeImg from "../../image/drugomics/vaccine-design-syringe.png";
import medicineBaseImg from "../../image/drugomics/medicine-base.png";
import medicineHelixImg from "../../image/drugomics/medicine-helix.png";
// import data
import servicesData from "../../data/content/drugomics/drugServices.json";
import drugPipelineData from "../../data/content/drugomics/drugPipeline.json";
import { DataFlat, DataStructure, DataKeys } from '../../types';
// import components
import { TitleSubtitle, CapsText } from "../display/TextFormat";
import { SpacingFrame } from "../display/Frame";
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";

const typedDrugPipeline : DataStructure = drugPipelineData;
const typedServices : DataStructure = servicesData;

interface DrugPipelineProps {
    showAll?: boolean;
};
export default function DrugPipeline({ showAll } : DrugPipelineProps) {
    const { lang } = useLang();
    let data = showAll ? typedDrugPipeline : Object.keys(typedDrugPipeline).slice(0, 9);
    let content = Object.keys(data).map((i) => {
        return (
            <Rows data={typedDrugPipeline[i]} />
        )   
    });
    
    return (
        <SpacingFrame>
            <TitleSubtitle title={ typedVocab["Drug Pipeline"][lang] } />
            <>
                <Header />
                <SpacingFrame>
                    { content }
                </SpacingFrame>
            </>
        </SpacingFrame>
    )
};

interface TableStructureProps {
    children: ReactNode;
};
function TableStructure({ children } : TableStructureProps) {
    return (
        <div className="w-full h-fit grid grid-cols-4 gap-6">
            { children }
        </div>
    )
};

function Header() {
    return (
        <TableStructure>
            <div className="col-span-1 lg:py-6 sm:py-2 break-words m-auto">
                <CapsText text="Disease Area" style="lg:text-sm sm:text-2xs font-bold" />
            </div>
            <div className="col-span-3 w-full m-auto">
                <div className="grid grid-cols-5 border-b-2 lg:gap-10 sm:gap-4 lg:py-6 sm:py-2 lg:break-normal sm:break-words">
                    <CapsText text="Target ID" style="lg:text-sm sm:text-2xs font-bold" />
                    <CapsText text="Hit to Lead" style="lg:text-sm sm:text-2xs font-bold" />
                    <CapsText text="Lead OPT. /POC" style="lg:text-sm sm:text-2xs font-bold" />
                    <CapsText text="Ind-Enabling" style="lg:text-sm sm:text-2xs font-bold" />
                    <CapsText text="Clinical" style="lg:text-sm sm:text-2xs font-bold" />
                </div>
            </div>
        </TableStructure>
    )
};

interface RowsProps {
    data: DataStructure;
};
function Rows({ data } : RowsProps) {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();
    const [showIcon, setShowIcon] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (screenSize === "Small") {
            setShowIcon(false);
        } else {
            setShowIcon(true);
        };
    }, [screenSize])

    // data
    let label = data["title"][lang];
    let info = data["text"][lang];
    let percent = Number(data["percentage"]);

    return (
        <TableStructure>
            <div className="col-span-1 flex items-center justify-center gap-2 justify-self-end">
                <p className="lg:text-sm sm:text-xs text-right">{ label }</p>
                { showIcon && 
                    <div className="relative h-fit w-fit">
                        <CirclePlus className="cursor-pointer hover:rounded-full hover:bg-grey-100 duration-200 ease-in-out" 
                            color={"#757575"} width={16} height={16} 
                            onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}
                        />
                        { showTooltip && <ToolTip text={ info } /> }
                    </div>
                }
            </div>
            <div className="col-span-3 w-full m-auto">
                <ProgressBar percentage={percent} />
            </div>
        </TableStructure>
    )
};

interface ProgressBarProps {
    percentage: number;
};
function ProgressBar({ percentage } : ProgressBarProps) {
    const gradientMap = {
        20: "to-teal-300",
        40: "to-teal-300",
        60: "to-teal-400",
        80: "to-teal-400",
        100: "to-teal-500"
    } as DataFlat;

    return (
        <div className="relative w-full h-8 rounded-full bg-grey-100">
            <motion.div className={`absolute h-8 rounded-full bg-gradient-to-r from-teal-100 ${gradientMap[percentage]}`} style={{ width: `${percentage}%` }} 
                initial={{ width: "40px" }}
                whileInView={{ width: `${percentage}%` }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.5 }}
            />
        </div>
    )
};

interface ToolTipProps {
    text: string;
};
function ToolTip({ text } : ToolTipProps) {
    const { lang } = useLang();
    return (
        <motion.div className="absolute bottom-0 -translate-x-[82.5px] -translate-y-[16px] z-50 pb-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <div className="relative">
                <div className="w-[180px] h-fit grid grid-flow-row auto-rows-min gap-1 bg-white rounded-lg shadow-lg px-4 py-3 m-auto">
                    <p className="text-xs font-medium">{ typedVocab["Advantages"][lang] }</p>
                    <p className="text-xs text-grey-400">{ text }</p>
                </div>
                <div className="absolute w-full -bottom-2">
                    <FontAwesomeIcon className="text-2xl text-white" icon={faSortDown} />
                </div>
            </div>
        </motion.div>
    )
};


export function DrugDesignSection() {
    let serviceCards = Object.keys(typedServices).map((i) => {
        let data = typedServices[i];
        data["key"] = i;
        return (
            <DrugomicsCard data={data} />
        )
    });

    return (
        <div className="w-full mt-12 flex gap-6 lg:flex-row sm:flex-col">
            { serviceCards }
        </div>
    )
}

interface DrugomicsCard {
    data: DataKeys;
};
function DrugomicsCard({ data } : DrugomicsCard) {
    const [key, setKey] = useState<string>("");
    const [imageAnim, setImageAnim] = useState<ReactNode>(<></>);
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [hover, setHover] = useState<boolean>(false);
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setKey(data["key"]);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            setLink(data["link"]);
        }; 
    }, [data, key, lang]);

    useEffect(() => {
        if (key === "drug-design") {
            setImageAnim(<DrugDesignTransition isHover={hover} />);
        } else if (key === "vaccine-design") {
            setImageAnim(<VaccineDesignTransition isHover={hover} />);
        } else if (key === "personalized-medicine") {
            setImageAnim(<PersonalizedMedTransition isHover={hover} />);
        } else {
            setImageAnim(<></>);
        };
    }, [key, hover]);

    return (
        <Link to={link} className="w-fit min-w-[250px] sm:w-[250px] h-fit min-h-[300px] grid grid-flow-row auto-rows-min gap-3 py-6 rounded-2xl m-auto
                                    group hover:bg-white transition-all duration-300 cursor-pointer"
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { imageAnim }
            <div className="w-full h-min flex items-center justify-center gap-2 text-md">
                <p className="w-fit font-bold group-hover:text-teal-500">{ title }</p>
                <ArrowUpRight className="group-hover:text-teal-500" size={25} />
            </div>
            <p className="text-sm text-grey-400 group-hover:text-teal-500">{ text }</p>
        </Link>
    )
};

export function OtherDrugServicesCard({ data } : DrugomicsCard) {
    const [key, setKey] = useState<string>("");
    const [imageAnim, setImageAnim] = useState<ReactNode>(<></>);
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [hover, setHover] = useState<boolean>(false);
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setKey(data["key"]);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            let fixedLink = data["absoluteLink"];
            if (lang === "Zh") {
                setLink("/tw" + fixedLink);
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, key, lang]);

    useEffect(() => {
        if (key === "drug-design") {
            setImageAnim(<DrugDesignTransition isHover={hover} />);
        } else if (key === "vaccine-design") {
            setImageAnim(<VaccineDesignTransition isHover={hover} />);
        } else if (key === "personalized-medicine") {
            setImageAnim(<PersonalizedMedTransition isHover={hover} />);
        } else {
            setImageAnim(<></>);
        };
    }, [key, hover]);

    return (
        <Link to={link} className="min-w-[240px] max-w-[460px] w-fit h-fit grid grid-cols-3 place-items-center gap-4 p-6 rounded-2xl m-auto
                        group hover:bg-white transition-all duration-300 cursor-pointer"
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}        
        >
            <div className="col-span-1">
                { imageAnim }
            </div>
            <div className="col-span-2 flex flex-col gap-2">
                <div className="w-fit flex items-center justify-center gap-2">
                    <p className="text-left text-lg font-semibold">{ title }</p>
                    <ArrowUpRight size={25} />
                </div>
                <p className="text-left text-sm text-grey-400">{ text }</p>
            </div>
        </Link>
    )
};


//////////////////////
// ANIMATED IMAGES //
/////////////////////
interface TransitionFrameProps {
    children: ReactNode;
};
function TransitionFrame({ children } : TransitionFrameProps) {
    const { screenSize } = useScreenSize();
    const [size, setSize] = useState<number>(128);

    useEffect(() => {
        if (screenSize === "Large") {
            setSize(128);
        } else if (screenSize === "Small") {
            setSize(112);
        };
    }, [screenSize]);

    return (
        <div className="relative m-auto overflow-hidden" style={{ width: `${size}px`, height: `${size}px` }}>
            { children }
        </div>
    )
};

interface TransitionProps {
    isHover: boolean;
};
function DrugDesignTransition({ isHover } : TransitionProps) {
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        setHover(isHover);
    }, [isHover]);

    const initialPosition = { x: 0, y: 0 }; 
    const finalPosition = { x: -45, y: -28, rotate: -90 };

    return (
        <TransitionFrame>
            <img src={drugBaseImg} alt="backgroundImg" className="absolute w-full h-full" />
            <motion.img
                src={drugPillImg}
                alt="Moving Component"
                initial={ initialPosition }
                animate={ hover ? finalPosition : initialPosition }
                transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                style={{ position: 'absolute', width: 'auto', height: 'auto' }}
            />
        </TransitionFrame>
    );
};

function VaccineDesignTransition({ isHover } : TransitionProps) {
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        setHover(isHover);
    }, [isHover]);

    const initialPosition = { x: 0, y: 0 }; 
    const finalPosition = { x: 8, y: -5, rotate: 18 };

    return (
        <TransitionFrame>
            <img src={vaccineBaseImg} alt="backgroundImg" className="absolute w-full h-full" />
            <motion.img
                src={vaccineSyringeImg}
                alt="Moving Component"
                initial={ initialPosition }
                animate={ hover ? finalPosition : initialPosition }
                transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                style={{ position: 'absolute', width: 'auto', height: 'auto' }}
            />
        </TransitionFrame>
    );
};

function PersonalizedMedTransition({ isHover } : TransitionProps) {
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        setHover(isHover);
    }, [isHover]);

    const initialPosition = { x: 0, y: 0 }; 
    const finalPosition = { x: 0, y: 0, rotateY: 180 };

    return (
        <TransitionFrame>
            <img src={medicineBaseImg} alt="backgroundImg" className="absolute w-full h-full" />
            <motion.img
                src={medicineHelixImg}
                alt="Moving Component"
                initial={ initialPosition }
                animate={ hover ? finalPosition : initialPosition }
                transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                style={{ position: 'absolute', width: 'auto', height: 'auto' }}
            />
        </TransitionFrame>
    );
};


