import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import './App.css';
// import components
import HomePage, { AllSolutions } from './components/pages/Homepage';
import Robotics, { Aiia, StoreSolutions, Adam } from "./components/pages/Robotics";
import Core, { CoreAiAssistant } from "./components/pages/Core";
import Finance, { FinanceSubpages, FinanceTraderAnita } from "./components/pages/Finance";
// import Energy, { NuclearFusion } from "./components/pages/Energy";
import Mobility, { MobilitySubpages } from "./components/pages/Mobility";
import Manufacturing, { ManufacturingSubpages } from "./components/pages/Manufacturing";
import Drugnomics, { DrugDesign, VaccineDesign, PersonalizedMedicine, DrugomicsPipeline } from "./components/pages/Drugomics";
import Health, { HealthSubpages } from "./components/pages/Health";
import Security, { SecuritySubpages } from "./components/pages/Security";
import About, { Technologies, TeamMembers } from "./components/pages/About/About";
import Atom, { AtomTools } from "./components/pages/About/Atom";
import Ardi, { ArdiTools} from "./components/pages/About/Ardi";
import AboutTechSubpages from "./components/pages/About/AIPlatforms";
import Careers, { ContactUs, OpenPositions } from "./components/pages/Careers";
import Blog, { BlogSubpages } from "./components/pages/Blog";
import NewsRoom, { NewsSubpages } from "./components/pages/Newsroom";
import NoContent from "./components/content/NoContent";
import JoinUsSection from "./components/content/JoinUs";
import Nav from "./components/navigation/NavBar";
import Footer from "./components/navigation/Footer";
// import functions
import { scrollTop } from "./functions/DisplayFunctions";
// import context
import { useLang, useNavTab, useMobileNavOpen } from "./components/Context";

export default function App() {
    const { lang } = useLang();
    const { setNavTab } = useNavTab();
    const { setMobileNavOpen } = useMobileNavOpen();
    const location = useLocation();
    const [showJoinUs, setShowJoinUs] = useState<boolean>(false);
    const [haveNav, setHaveNav] = useState<boolean>(true);

    // close navBar when navigating to new page
    useEffect(() => {
        setNavTab(d => ({...d, Name: "", Open: false}));
        scrollTop();
        setMobileNavOpen(false);

        if (location.pathname === "/contact-us") {
            setShowJoinUs(false);
        } else {
            setShowJoinUs(true);
        };

        if (location.pathname === "/robotics/aiia-brochure") {
            setHaveNav(false);
            setShowJoinUs(false);
        } else {
            setHaveNav(true);
            setShowJoinUs(true);
        };
    }, [location.pathname]);

    useEffect(() => {
        setNavTab(d => ({...d, Name: "", Open: false}));
        setMobileNavOpen(false);
    }, [lang]);

    return (
        <>
            { haveNav && <Nav /> }
            <Routes>
                <Route path={ lang === "Zh" ? "/tw" : "/" } element={ <HomePage /> } />
                <Route path={ lang === "Zh" ? "/tw/all-solutions" : "/all-solutions" }element={ <AllSolutions /> } />

                <Route path={ lang === "Zh" ? "/tw/robotics" : "/robotics" } element={ <Robotics /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/digital-human" : "/robotics/digital-human" } element={ <Aiia /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/store-solutions" : "/robotics/store-solutions" } element={ <StoreSolutions /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/adam" : "/robotics/adam" } element={ <Adam /> } />

                <Route path={ lang === "Zh" ? "/tw/core" : "/core" } element={ <Core /> } />
                <Route path={ lang === "Zh" ? "/tw/core/ardi-platform" : "/core/ardi-platform" } element={<Navigate to="/about/technologies/ardi" replace />} />
                {/* <Route path={ lang === "Zh" ? "/tw/core/ai-assistant" : "/core/ai-assistant" } element={ <CoreAiAssistant /> } /> */}

                <Route path={ lang === "Zh" ? "/tw/finance" : "/finance" } element={ <Finance /> } />
                <Route path={ lang === "Zh" ? "/tw/finance/:financeSolutions" : "/finance/:financeSolutions" } element={ <FinanceSubpages /> } />
                <Route path={ lang === "Zh" ? "/tw/finance/personal-financial-advisor/alpha-ai-trader-anita" : "/finance/personal-financial-advisor/alpha-ai-trader-anita" } element={ <FinanceTraderAnita /> } />

                {/* <Route path="/energy" element={ <Energy /> } /> */}
                {/* <Route path="/energy/nuclear-fusion" element={ <NuclearFusion /> } /> */}

                <Route path={ lang === "Zh" ? "/tw/drugomics" : "/drugomics" } element={ <Drugnomics /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design" : "/drugomics/drug-design" } element={ <DrugDesign /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design/pipeline" : "/drugomics/drug-design/pipeline" } element={ <DrugomicsPipeline /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/vaccine-design" : "/drugomics/vaccine-design" } element={ <VaccineDesign /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/personalized-medicine" : "/drugomics/personalized-medicine" } element={ <PersonalizedMedicine /> } />

                <Route path={ lang === "Zh" ? "/tw/health" : "/health" } element={ <Health /> } />
                <Route path={ lang === "Zh" ? "/tw/health/:healthSolutions" : "/health/:healthSolutions" } element={ <HealthSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/mobility" : "/mobility" } element={ <Mobility /> } />
                <Route path={ lang === "Zh" ? "/tw/mobility/:mobilitySolutions" : "/mobility/:mobilitySolutions" } element={ <MobilitySubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/manufacturing" : "/manufacturing" } element={ <Manufacturing /> } />
                <Route path={ lang === "Zh" ? "/tw/manufacturing/:manufacturingSolutions" : "/manufacturing/:manufacturingSolutions" } element={ <ManufacturingSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/security" : "/security" } element={ <Security /> } />
                <Route path={ lang === "Zh" ? "/tw/security/:securitySolutions" : "/security/:securitySolutions" } element={ <SecuritySubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/about" : "/about" } element={ <About /> } />
                <Route path={ lang === "Zh" ? "/tw/about/team" : "/about/team" } element={ <TeamMembers /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies" : "/about/technologies" } element={ <Technologies /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/atom" : "/about/technologies/atom" } element={ <Atom /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/atom/:atomTools" : "/about/technologies/atom/:atomTools" } element={ <AtomTools /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/ardi" : "/about/technologies/ardi" } element={ <Ardi /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/ardi/:ardiTools" : "/about/technologies/ardi/:ardiTools" } element={ <ArdiTools /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/:aboutTech" : "/about/technologies/:aboutTech" } element={ <AboutTechSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/careers" : "/careers" } element={ <Careers /> } />
                <Route path={ lang === "Zh" ? "/tw/careers/:openPositions" : "/careers/:openPositions" } element={ <OpenPositions /> } />
                <Route path={ lang === "Zh" ? "/tw/contact-us" : "/contact-us" } element={ <ContactUs /> } />

                <Route path={ lang === "Zh" ? "/tw/blog" : "/blog" } element={ <Blog /> } />
                <Route path={ lang === "Zh" ? "/tw/blog/:blogPosts" : "/blog/:blogPosts" } element={ <BlogSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/newsroom" : "/newsroom" } element={ <NewsRoom /> } />
                <Route path={ lang === "Zh" ? "/tw/newsroom/:newsStories" : "/newsroom/:newsStories" } element={ <NewsSubpages /> } />

                <Route path="*" element={ <NoContent /> } />
            </Routes>
            { showJoinUs && <JoinUsSection /> }
            { haveNav && <Footer /> }
        </>
    );
};

