import { useEffect } from "react";

interface VideoPlayerProps {
    url: string;
    height: number;
}
VideoPlayer.defaultProps = {
    height: 600
}
export function VideoPlayer({ url, height } : VideoPlayerProps) {
    // Function to check if the URL is a YouTube link
    function isYouTubeUrl(url: string) {
        const pattern = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
        return pattern.test(url);
    };

    // Function to extract the YouTube ID from the URL
    function extractYouTubeId(url: string) {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : null;
    };

    // Determine the correct src for the iframe
    function getIframeSrc() {
        if (isYouTubeUrl(url)) {
            const videoId = extractYouTubeId(url);
            return `https://www.youtube.com/embed/${videoId}`;
        }
        return url;
    };
  
    return (
        <iframe src={getIframeSrc()} width="100%" height={`${height}px`} className="z-40"
            allow="accelerometer; autoplay *; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
    );
};

interface PDFViewerProps {
    src: string;
};
export function PDFViewer({ src } : PDFViewerProps) {
    return (
        // <div className="w-screen h-screen">
        //     <embed src={src} type="application/pdf" width="100%" height="100%" />
        // </div>
        <div className="h-screen">
            <iframe className="w-full h-screen overflow-y-auto" src={src} width="100%" height="100%" />
        </div>
    );
};