import { useState, useEffect, useRef, ReactNode, forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
// import data
import businessUnitsData from "../../data/businessUnits.json";
import navUnitsData from "../../data/navUnits.json";
import { DataStructure, DataKeys, DataFlat } from '../../types';
// import context
import { useLang, useNavTab } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";

const typedBusinessUnitsData : DataStructure = businessUnitsData;
const typedNavUnits : DataStructure = navUnitsData;

interface NavToggleButtonProps {
    id: string;
    children: ReactNode;
};
export function NavToggleButton({ id, children } : NavToggleButtonProps) {
    const [position, setPosition] = useState<number>(0);
    const componentRef = useRef<HTMLDivElement>(null);
    // flags
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { navTab, setNavTab } = useNavTab();

    useEffect(() => {
        if (isClicked) {
            setNavTab({
                "Name": id, 
                "Open": isOpen, 
                "LeftPos": position
            });
        };
    }, [isOpen, position]);

    useEffect(() => {
        if (navTab["Name"] !== id) {
            setIsClicked(false);
            setIsOpen(false);
        };
    }, [navTab]);

    useEffect(() => {
        if (componentRef.current) {
            const elementPosition = componentRef.current.getBoundingClientRect();
            // set the starting position relative to the viewport
            setPosition(elementPosition.left);
        };
      }, [isClicked]);

    function setClickedOpen() {
        setIsClicked(true);
        setIsOpen(!isOpen)
    };

    let style = isOpen
                ? "flex items-center justify-center gap-2 cursor-pointer select-none pb-1 border-b-1 border-white"
                : "flex items-center justify-center gap-2 cursor-pointer select-none pb-1 border-b-1 border-transparent"

    return (
        <div ref={componentRef} id={id} className={style} onClick={() => setClickedOpen()}>
            { children }
            <FontAwesomeIcon className="text-white" icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
    )
};

export function AllGraphen() {
    return (
        <div className="grid grid-cols-4 gap-5 gap-y-8">
            <ShowAllBusinessUnits showIcons />
        </div>
    )
};

export function LanguageSelect() {
    const { lang, setLang } = useLang();

    let showLang = Object.keys(typedNavUnits["Language"]).map((i) => {
        let style = lang === i
                    ? "text-right cursor-pointer hover:text-teal-300 text-teal-300 "
                    : "text-right cursor-pointer hover:text-teal-300 text-white"
        return (
            <p id={i} className={style} onClick={() => setLang(i)}>
                {typedNavUnits["Language"][i]}
            </p>
        )
    });

    return (
        <div className="grid gap-2">
            <p className="text-right text-white font-bold">{typedVocab["Select Language"][lang]}</p>
            { showLang }
        </div>
    )
};

export const OtherTabs = forwardRef<HTMLDivElement>((props, ref) => {
    const [title, setTitle] = useState<string>("");
    const [dropdownList, setDropdownList] = useState<DataFlat>({});
    const { lang } = useLang();
    const { navTab } = useNavTab();

    useEffect(() => {
        setTitle(navTab["Name"]);
        if (typedNavUnits[navTab["Name"]]) {
            setDropdownList(typedNavUnits[navTab["Name"]][lang]);
        };
    }, [navTab]);

    return (
        <div className="relative h-fit pb-5">
            <div ref={ref} className="h-max fixed flex flex-col gap-2" style={{ left: `${navTab["LeftPos"]}px` }}>
                <p className="text-left text-white font-bold">{ typedVocab[title][lang] }</p>
                <ShowUnits data={dropdownList} />
            </div>
        </div>
    )
});

interface ShowAllBusinessUnitsProps {
    showIcons?: boolean;
    isFooter?: boolean;
};
export function ShowAllBusinessUnits({ showIcons, isFooter } : ShowAllBusinessUnitsProps) {
    const { lang } = useLang();
    const location = useLocation();
    const [selUnit, setSelUnit] = useState<string>("");

    useEffect(() =>{
        // extract the root business path
        let path = location.pathname.split("/")[1];
        setSelUnit(path);
    }, [location.pathname]);

    let showUnits = Object.keys(typedBusinessUnitsData).map((i: any) => {
        try {
            let data = typedBusinessUnitsData[i][lang] as DataKeys;
            let image = typedBusinessUnitsData[i]["Image"] as String;
            let title = Object.keys(data)[0];
            let unitList = data[title];

            let showList = Object.keys(unitList).map((u) => {
                let label = unitList[u]
                return (
                    <Link to={ lang === "Zh" ? `/tw/${i}/${u}` : `/${i}/${u}` } className="text-left text-white text-nowrap lg:text-sm sm:text-base cursor-pointer hover:text-teal-300">{label}</Link>
                )
            });

            let defaultStyle = "text-left font-bold cursor-pointer text-white hover:text-teal-300";
            let titleStyle = i === selUnit ? "text-left font-bold cursor-pointer text-teal-300" : defaultStyle;

            return (
                <div>
                    <div className="grid grid-flow-col auto-cols-max gap-1 mb-2">
                        { showIcons && <img className="max-h-5" src={require("../../image/" + image)} alt="" />}
                        <Link to={ lang === "Zh" ? `/tw/${i}` : `/${i}`} className={ isFooter ? defaultStyle : titleStyle}>{title}</Link>
                    </div>
                    <div className="grid gap-1">
                        { showList }
                    </div>
                </div>
            )
        } catch {
            return (
                <></>
            )
        }
    });

    return (
        <>
            { showUnits }
        </>
    )
};

interface ShowUnitsProps {
    data: DataKeys;
};
export function ShowUnits({ data } : ShowUnitsProps) {
    const { lang } = useLang();
    let showList = Object.keys(data).map((i) => {
        let label = data[i];
        return (
            <Link to={ lang === "Zh" ? `/tw/${i}` : `/${i}` } className="text-left lg:text-sm sm:text-base text-white cursor-pointer hover:text-teal-300">{label}</Link>
        )
    });

    return (
        <>
            { showList }
        </>
    )
};


interface MobileDropdownUnitProps {
    id: string;
    label: string;
    toggle?: boolean;
};
export function MobileDropdownUnit({ id, label, toggle } : MobileDropdownUnitProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function toggleOpen() {
        if (toggle) {
            setIsOpen(!isOpen)
        };
    };

    return (
        <>
            <div id={id} className="w-full px-5 py-3 flex text-md cursor-pointer border-b-1 border-grey-300" onClick={toggleOpen}>
                <p className="text-left text-white select-none">{ label }</p>
                { toggle && <FontAwesomeIcon className="ml-auto text-white" icon={ isOpen ? faChevronUp : faChevronDown } />}
            </div>
            { isOpen && <MobileDropdownContent id={id} /> }
        </>
    )
};

interface MobileDropdownContentProps {
    id: string;
};
export function MobileDropdownContent({ id } : MobileDropdownContentProps) {
    const [content, setContent] = useState<ReactNode>(<></>)
    const { lang } = useLang();

    useEffect(() => {
        if (id === "Business Units") {
            setContent(<MobileAllGraphen />);
        } else {
            setContent(<DropdownList data={typedNavUnits[id][lang]} />)
        };
    }, [id]);


    return (
        <div className="px-10 py-5">
            { content }
        </div>
    )
};

export function MobileAllGraphen() {
    const { lang } = useLang();

    let showUnits = Object.keys(typedBusinessUnitsData).map((i: any) => {
        let data = typedBusinessUnitsData[i][lang] as DataKeys;
        let image = typedBusinessUnitsData[i]["Image"] as String;
        let title = Object.keys(data)[0];
        let unitList = data[title];

        return (
            <Link to={ lang === "Zh" ? `/tw/${i}` : `/${i}`}>
                <DropdownHeader title={title} image={image} />
                <div className="grid gap-1 mb-2">
                    <DropdownList data={unitList} parentLink={i} />
                </div>
            </Link>
        )
    });

    return (
        <>
            { showUnits }
        </>
    )
};

interface DropdownListProps {
    data: DataFlat;
    parentLink?: string;
};
export function DropdownList({ data, parentLink } : DropdownListProps) {
    const { lang } = useLang();

    let showList = Object.keys(data).map((i) => {
        let label = data[i];
        let link = parentLink ? parentLink + "/" + i : i;
        return (
            <Link to={ lang === "Zh" ? `/tw/${link}` : `/${link}`} className="text-left text-white lg:text-sm sm:text-base cursor-pointer hover:text-teal-300">{label}</Link>
        )
    });

    return (
        <div className="grid gap-1 mb-2">
            { showList }
        </div>
    )
};

interface DropdownHeader {
    title: String;
    image?: String;
};
export function DropdownHeader({ title, image } : DropdownHeader) {
    return (
        <div className="grid grid-flow-col auto-cols-max gap-1 mb-2 py-3 border-b-1 border-grey-300">
            { image && <img className="max-h-5" src={require("../../image/" + image)} alt="" /> }
            <p className="text-left text-white font-bold cursor-pointer hover:text-teal-300">{title}</p>
        </div>
    )
};


