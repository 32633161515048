import { useState, useEffect, useRef, createRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import icons
import { faChevronLeft, faChevronRight, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
// import image
import aiiFrame from "../../image/robotics/aiia_frame.png";
import kioskGroup from "../../image/robotics/kiosk_group.png";
// import data
import aiiaRolesData from "../../data/content/robotics/aiiaRoles.json";
import aiiaModelsData from "../../data/content/robotics/aiiaModels.json";
import { DataKeys, DataStructure } from "../../types";
// import components
import { SpacingFrame } from "../display/Frame";
import { TitleSubtitle } from "../display/TextFormat";
import { ImageImport } from "../display/ImageFormat";
// import context
import { useLang, useScreenSize } from "../Context";
// import global var
import { maxWidthScreen, typedVocab } from "../GlobalVar";

const typedRoles : DataStructure = aiiaRolesData;
const typedModels : DataStructure = aiiaModelsData;

export function AiiaRolesSlideShow() {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();
    const roleList = Object.keys(typedRoles);

    const [selRole, setSelRole] = useState<string>(roleList[0]);
    const [selData, setSelData] = useState<DataKeys | undefined>(undefined);
    const [image, setImage] = useState("");
    const [color, setColor] = useState<string>("");
    const [leftPos, setLeftPos] = useState<string>("");
    const [arrowPos, setArrowPos] = useState<string>("h-[900px] px-24");
    const [isNext, setIsNext] = useState<boolean>(true);


    useEffect(() => {
        if (selRole) {
            setSelData(typedRoles[selRole]);
        };
    }, [selRole]);

    useEffect(() => {
        if (screenSize === "Small") {
            setArrowPos("h-[240px] px-2");
        } else if (screenSize === "Large") {
            setArrowPos("h-[900px] px-24");
        };
    }, [screenSize]);

    useEffect(() => {
        if (selData) {
            let imageSrc = require("../../image/robotics/" + selData["image"]);
            setImage(imageSrc);
            setColor(selData["color"]);
            setLeftPos(selData["left"]);
        }
    }, [selData]);

    function newSelection(newRole: string) {
        const currentIndex = roleList.indexOf(selRole);
        const newIndex = roleList.indexOf(newRole);

        if (newIndex > currentIndex) {
            setIsNext(true);
        } else if (newIndex < currentIndex) {
            setIsNext(false);
        };
        setSelRole(newRole);
        scrollToItem(newIndex);
    };

    const itemRefs = useRef<any[]>([]);
    useEffect(() => {
        itemRefs.current = Object.keys(typedRoles).map(() => createRef());
    }, [typedRoles]);

    function scrollToItem(index : any) {
        // Access the ref of the item to scroll into view
        const itemRef = itemRefs.current[index];
        if (itemRef && itemRef.current) {
            itemRef.current.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'nearest',  
                inline: 'start' 
            });
        }
    };

    let headers = Object.keys(typedRoles).map((i, index) => {
        let title = typedRoles[i]["label"][lang];
        let textStyle = selRole === i
                        ? "text-md text-nowrap text-teal-500 font-semibold group-hover:text-teal-500"
                        : "text-md text-nowrap text-grey-700 font-semibold group-hover:text-teal-500"
        return (
            <div onClick={() => newSelection(i)} ref={itemRefs.current[index]}
                className={ `${selRole === i && "w-fit h-fit border-b-2 border-b-teal-500"}` }
            >
                <div className="group w-fit rounded-md py-2 px-4 border-2 border-transparent cursor-pointer 
                                hover:bg-grey-100/50 transition-all duration-300">
                    <p className={ textStyle }>{ title }</p>
                </div>
            </div>
        )
    });

    function nextItem(next: boolean) {
        const currentIndex = roleList.indexOf(selRole);
        // create loop
        let direction = next ? 1 : -1;
        let nextIndex = (currentIndex + direction) % roleList.length;
        if (nextIndex < 0) {
            nextIndex = roleList.length - 1
        };
        setIsNext(next)
        setSelRole(roleList[nextIndex]);
        scrollToItem(nextIndex);
    };

    let scrollDisplay = <div className={`absolute left-0 w-screen ${arrowPos} flex items-center justify-between z-30`}>
                            <div className="cursor-pointer" onClick={() => nextItem(false)}>
                                <FontAwesomeIcon className="text-xl text-teal-100" icon={faChevronLeft} />
                            </div>
                            <div className="cursor-pointer" onClick={() => nextItem(true)}>
                                <FontAwesomeIcon className="text-xl text-teal-100" icon={faChevronRight} />
                            </div>
                        </div>

    return (
        <>
            { scrollDisplay }
            <SpacingFrame spacing={48}>
                <div className="w-full grid grid-flow-col auto-cols-auto lg:gap-4 sm:gap-1 overflow-scroll z-40">
                    { headers }
                </div>
                { selData &&
                    <div className="w-full lg:grid lg:grid-cols-5 sm:flex sm:flex-col-reverse gap-8 overflow-hidden">
                        <div className="col-span-3 transition duration-500 z-40">
                            <SpacingFrame>
                                <p className="text-xl text-left font-semibold">{ selData["title"][lang] }</p>
                                <p className="text-left">
                                    <span className="font-semibold">{ typedVocab["Scenarios"][lang] }: </span>
                                    <span>{ selData["scenarios"][lang] }</span>
                                </p>
                                <p className="text-left">{ selData["description"][lang] }</p>
                                <CreateInfo data={ selData["content"] } />
                            </SpacingFrame>
                        </div>
                        <div className="col-span-2 relative m-auto">
                            <div className="absolute top-[52px] left-[32px]" style={{ left: leftPos }}>
                                <motion.img className="w-72 z-20" src={image} 
                                    key={selRole}
                                    transition={{ type: "tween", duration: 0.5 }}
                                    initial={{ x: isNext ? "100%" : "-80%" }}
                                    animate={{ x: 0 }}
                                    exit={{ x: "-100%" }}
                                />
                            </div>
                            <img className="absolute top-0 w-[340px] z-40" src={aiiFrame} />
                            <div className="absolute top-0 -left-[180px] w-[200px] h-[650px] bg-white z-30"/>
                            <div className="absolute top-0 -right-[180px] w-[200px] h-[650px] bg-white z-30"/>
                            <img className="w-[340px] z-50" src={aiiFrame} />

                            <div className="absolute top-[106px] left-[30px] w-[118px] h-10 z-10 p-2 flex flex-col gap-1.5
                                            shadow-bubble bg-white rounded-xl rounded-br-none">
                                <hr className="w-full h-0.5 bg-grey-100 rounded border-0"/>
                                <hr className="w-full h-0.5 bg-grey-100 rounded border-0"/>
                                <hr className="w-1/2 h-0.5 bg-grey-100 rounded border-0"/>
                            </div>
                            <div className="absolute top-[160px] left-[30px] w-[85px] h-10 z-10 px-2 py-3 flex flex-col gap-1.5
                                            shadow-bubble bg-white rounded-xl rounded-bl-none transition duration-500" style={{ backgroundColor: color }}>
                                <hr className="w-full h-0.5 bg-grey-600 rounded border-0"/>
                                <hr className="w-1/2 h-0.5 bg-grey-600 rounded border-0"/>
                            </div>
                        </div>
                    </div>
                }
            </SpacingFrame>
        </>
    )
};

interface CreateInfoProps {
    data: DataStructure;
};
function CreateInfo({ data } : CreateInfoProps) {
    const { lang } = useLang();

    let showInfo = Object.keys(data).map((i) => {
        let info = data[i];
        let image = require("../../image/icons/" + info["icon"]);
        return (
            <div className="flex gap-4">
                <img className="flex items-start justify-start mb-auto" src={image} />
                <SpacingFrame spacing={12}>
                    <p className="text-md text-left text-teal-500 font-bold">{ info["title"][lang] }</p>
                    <p className="text-sm text-left text-grey-400">{ info["text"][lang] }</p>
                </SpacingFrame>
            </div>
        )
    });

    return (
        <SpacingFrame>
            { showInfo }
        </SpacingFrame>
    )
};

export function AiiaHardwareModels() {
    const { lang } = useLang();
    const [selModel, setSelModel] = useState<string>("");

    function selectModel(model: string) {
        if (selModel === model) {
            setSelModel("");
        } else {
            setSelModel(model);
        };
    };

    let sideBar = Object.keys(typedModels).map((i) => {
        let data = typedModels[i];

        return (
            <motion.div onClick={() => selectModel(i)}
                className={`w-[90%] h-fit flex flex-col gap-2 border-l-4 py-0.5 px-3 cursor-pointer lg:m-0 sm:m-auto
                            ${ selModel === i ? "border-teal-500" : "border-teal-300 hover:bg-teal-300/40"} transition duration-500`}
                initial={{ opacity: 0 }}
                animate={selModel === i ? { height: "auto", opacity: 1 } : { height: "32px", opacity: 1 }}
                exit={{ height: "32px", opacity: 0 }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
                style={{ overflow: 'hidden' }}
            >
                <div className="flex">
                    <p className="w-full text-left text-lg font-semibold">{ data["title"][lang] }</p>
                    <div className="relative w-fit flex items-center justify-center">
                        <FontAwesomeIcon className="absolute right-0 text-md transition duration-300" icon={ selModel === i ? faChevronUp : faChevronDown } />
                    </div>
                </div>
                { selModel === i ? 
                    <motion.p className="text-left text-sm text-grey-400"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        { data["description"][lang] }
                    </motion.p> 
                    : 
                    <motion.p className="text-left text-sm text-grey-400"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        { data["description"][lang] }
                    </motion.p> 
                }
            </motion.div>
        )
    });

    function CreateImageSlides() {
        if (selModel) {
            let data = typedModels[selModel];
            let imageSrc = data["image"];
            let sketchSrc = data["sketch"];
            return (
                <motion.div className="grid grid-flow-col auto-cols-auto"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                >
                    <ImageImport image={sketchSrc} imagePath="image/robotics/" />
                    <ImageImport image={imageSrc} imagePath="image/robotics/" />
                </motion.div>
            )
        } else {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <img src={kioskGroup} />
                </motion.div>
            )
        };
    };

    return (
        <div className="relative w-full lg:h-screen sm:h-[1000px] bg-cover bg-center bg-white overflow-hidden">
            <div className="absolute w-[700px] h-[700px] rounded-full blur-3xl filter opacity-60 top-[25%] left-[50%] z-10" style={{ backgroundColor: "#57C4CB" }}/>

            <div className="absolute w-full z-40">
                <div className="m-auto" style={{ maxWidth: `${maxWidthScreen}px` }}>
                    <div className="h-fit flex flex-col lg:gap-40 sm:gap-10">
                        <TitleSubtitle title="Aiia Models for Different Business Needs" />
                        <div className="lg:grid lg:grid-cols-3 sm:flex sm:flex-col gap-8">
                            <div className="col-span-1 flex flex-col items-top justify-top gap-8 lg:px-0 sm:px-8">
                                { sideBar }
                            </div>
                            <div className="col-span-2 lg:px-0 sm:px-8">
                                <CreateImageSlides />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};