// import images
import brainImg from "../../image/banner.jpg";
// import data
import contentData from "../../data/content/homepage/homepageContent.json";
import allSolutionsData from "../../data/content/homepage/allSolutionsContent.json";
import aiToolsData from "../../data/aiTools.json";
import { DataKeys, DataStructure } from '../../types';
// import components
import { MainFrame, ImageBannerFrame, HorizontalScrollFrame, HorizontalScrollArrowsFrame, Col2ContentFrame, Col3ContentFrame, SpacingFrame } from "../display/Frame";
import { MoreButton } from "../tools/Buttons";
import { ToolCard, BusinessUnitCard, MemberCard } from "../display/Cards";
import { TitleSubtitle } from "../display/TextFormat";
import { UpdateSummary } from "../content/BlogNews";
import OfficeLocationsMap from "../content/MapLocations";
import { TechBanner } from "./About/About";
import { NavTree } from "../navigation/NavLinkPages";
import { SlideInAnim } from "../tools/Animations";
// import function
import { filterPeopleData } from "../../functions/DataFunctions";
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useScreenSize, useLang } from "../Context";
// import global var
import { maxWidthScreen, typedVocab } from "../GlobalVar";

const maxWidth2Thirds = Math.floor((maxWidthScreen / 3) * 2);
const typedContent : DataStructure = contentData;
const typedAllSolutions : DataStructure = allSolutionsData;
const typedTools : DataStructure = aiToolsData;


export default function HomePage() {
    const { screenSize } = useScreenSize();
    const { lang } = useLang();

    // extract section data
    const section1 = typedContent[1];
    const section2 = typedContent[2];
    const section3 = typedContent[3];
    const section4 = typedContent[4];
    const section5 = typedContent[5];
    const section6 = typedContent[6];
    const section7 = typedContent[7];

    // ai tools
    let toolsCard = Object.keys(typedTools).map((i) => {
        let data = typedTools[i];
        return (
            <ToolCard data={data} />
        )
    });

    let section3LgDisplay = <div className="w-full min-h-96 h-fit grid grid-cols-3">
                                <div className="col-span-1 py-16">
                                    <SpacingFrame>
                                        <TitleSubtitle 
                                            title={ section3["title"][lang] }
                                            subtitle={ section3["subtitle"][lang] }
                                            left
                                        />
                                        <MoreButton label="Explore All Technologies" link="/about/technologies" darkMode />
                                    </SpacingFrame>
                                </div>
                                <div className="col-span-2 relative">
                                    <div className={`absolute max-w-[66.66vw]`} 
                                        style={{ width: `calc((100vw - ${maxWidthScreen}px) / 2 + ${maxWidth2Thirds}px)`, marginRight: `calc((100vw - ${maxWidthScreen}px) / -2)` }}
                                    >
                                        <HorizontalScrollFrame>
                                            { toolsCard }
                                        </HorizontalScrollFrame>
                                    </div>
                                </div>
                            </div>

    let section3SmDisplay = <SpacingFrame>
                                <TitleSubtitle 
                                    title={ section3["title"][lang] }
                                    subtitle={ section3["subtitle"][lang] }
                                    left
                                />
                                <HorizontalScrollFrame>
                                    { toolsCard }
                                </HorizontalScrollFrame>
                                <MoreButton label="Explore All Technologies" link="/about/technologies" />
                            </SpacingFrame>

    // section 5: business units
    let businessUnitsCard = Object.keys(section5["content"]).map((i) => {
        let data = section5["content"][i];
        return (
            <BusinessUnitCard data={data} imagePath="image/" />
        )
    });

    // section 7: people
    const peopleData = filterPeopleData(section7["people"]) as DataStructure;
    let membershipCards = Object.keys(peopleData).map((i) => {
        let data = peopleData[i] as DataKeys;
        return (
            <MemberCard data={data} />
        )
    });

    return (
        <>
            <ImageBannerFrame image={brainImg}>
                <div className="w-[75%] m-auto">
                    <p className="text-3xl text-white font-bold sm:leading-none">
                        <span>{ section1["title"][lang] }</span>
                        <span className="text-teal-300">{ typedVocab["Graphen"][lang] }</span>
                    </p>
                    <p className="text-base text-white sm:mt-2">{ section1["subtitle"][lang] }</p>
                </div>
            </ImageBannerFrame>

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle 
                        title={ section2["title"][lang] } 
                        subtitle={ section2["subtitle"][lang] }
                    />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section2["solutions"] } haveTag />
                    </Col3ContentFrame>
                    <MoreButton label="View All Solutions" link="all-solutions" />
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#A2DADB" }}>
                { screenSize == "Large" ? section3LgDisplay : section3SmDisplay }
            </MainFrame>

            <DisplayInfo2Cols contentData={ section4["content"] } imagePath="image/" title={ section4["title"][lang] } subtitle={ section4["subtitle"][lang] } />

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle 
                        title={ section5["title"][lang] }
                        subtitle={ section5["subtitle"][lang] }
                    />
                    <div className="lg:grid lg:grid-cols-4 gap-3 sm:flex sm:flex-col">
                        { businessUnitsCard }
                    </div>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle 
                        title={ section6["title"][lang ]}
                        subtitle={ section6["subtitle"][lang ]}
                    />
                    <OfficeLocationsMap />
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle 
                        title={ section7["title"][lang] }
                        subtitle={ section7["subtitle"][lang] }
                    />
                    <div>
                        { screenSize === "Large" && <MoreButton label="View All Members" link="/about/team" /> }
                        <HorizontalScrollArrowsFrame>
                            { membershipCards }
                        </HorizontalScrollArrowsFrame>
                        { screenSize === "Small" && <MoreButton label="View All Members" link="/about/team" /> }
                    </div>
                </SpacingFrame>
            </MainFrame>

            <UpdateSummary />
        </>
    )
};


export function AllSolutions() {
    const { lang } = useLang();
    const section1 = typedAllSolutions[1];
    const section2 = typedAllSolutions[2];
    const section3 = typedAllSolutions[3];
    const section4 = typedAllSolutions[4];
    const section5 = typedAllSolutions[5];
    const section6 = typedAllSolutions[6];

    return (
        <>
            <TechBanner title={ typedAllSolutions["title"][lang] } subtitle={ typedAllSolutions["subtitle"][lang] } />
            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section2["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section2["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section3["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section3["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section4["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section4["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section5["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section5["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section6["title"][lang] } />
                    <Col3ContentFrame>
                        <DisplayProductCards data={ section6["solutions"] } haveTag />
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>
        </>
    )
};